import React, { Component } from 'react';
import Search from '../../components/Search';
import  axios from '../../services/axios'
import _ from 'lodash'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import moment from 'moment'
import { Toast } from 'primereact/toast';
import { Paginator } from 'primereact/paginator';
import { Dialog } from 'primereact/dialog';
import Input from '../../components/Input';
import { InputSwitch } from 'primereact/inputswitch';

class GetOrderStatus extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      total: 0,
      loading: true,
      displayBasic: false,
      displayBasic2: false,
      displayBasic3: false,
      displayModal: false,
      displayConfirmation: false,
      displayMaximizable: false,
      displayPosition: false,
      position: 'center',
      dialog: {},
      change: true,
      first: 0,
      page: 1
    }
    this.getTable = this.getTable.bind(this);
  }
  renderFields = [
    {
      component: "text",
      options: [],
      value: '',
      label: "Tên",
      width: 3,
      key: "name",
      onKeyPress: (event) => {
        if (event.charCode === 13) {
          console.log("ok vua an enter")
          console.log(this.renderFields)
        }
      }
    }]
  renderFields2 = [
    
    {
      component: "text-number",
      options: [],
      value: 1,
      label: "Thứ tự",
      width: 4,
      key: "order_id",
    },
    {
      component: "text",
      options: [],
      value: '',
      label: "Tên",
      width: 4,
      key: "name",
    },
    {
      component: "text",
      options: [],
      value: '',
      label: "Mã định danh",
      width: 4,
      key: "alias",
    },
  ]

  async componentDidMount() {
    let data = {}
    for (let i = 0; i < this.renderFields.length; i++) {
      data[this.renderFields[i].key] = this.renderFields[i].value
    }
    this.getTable(data)
    this.setState({ loading: true })
    return true
  }
  showSuccess() {
    this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Thành công', life: 3000 });
  }

  showError(detail) {
    this.toast.show({ severity: 'error', summary: 'Error Message', detail: detail ? detail : 'Có lỗi xảy ra', life: 3000 });
  }


  async getTable(data) {
    // console.log("data", data)
    let response = await axios.get(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/order_status`, {
      params: {
        ...data
      }
    });
    // let response = await axios.get(`http://localhost:1348/v1/api/order_status`, {
    //   params: {
    //     ...data
    //   }
    // });
    if (response && response.data && response.data.data) {
      // let dataR = _.map(response.data.data.data, ite => {
      //   return { ...ite, created_at: moment(ite.created_at).format("HH:mm:ss DD/MM/YYYY") }
      // })
      response.data.data.success=!response.data.data.deleted
      this.setState({ data: response.data.data.data,total:response.data.data.total})
      console.log(this.state.data)
    }
    //   dataBox[3].total = dataBox[1].total - dataBox[2].total
    //   this.setState({ data: dataBox })
    // }
  }
  onPageChange = (event) => {
    console.log(event.page)
    this.setState({
      first: event.first,
      // rows:event.rows
    });
    let data = {}
    for (let i = 0; i < this.renderFields.length; i++) {
      data[this.renderFields[i].key] = this.renderFields[i].value
    }
    data.page_index = event.page + 1
    this.getTable(data)
  }
  onClick(name, position) {
    let state = {
      [`${name}`]: true,
    };

    if (position) {
      state = {
        ...state,
        position,
      }
    }
    this.setState(state);
  }
  onHide(name) {
    this.setState({
      [`${name}`]: false
    });
  }
  onClick2(name, position, rowData) {
    let state = {
      [`${name}`]: true,
      dialog: rowData
    };

    if (position) {
      state = {
        ...state,
        position,
        dialog: rowData
      }
    }
    this.renderFields2[0].value = rowData.order_id
    this.renderFields2[1].value = rowData.name
    this.renderFields2[2].value = rowData.alias

    this.setState(state);
  }

  createFile = async (name) => {
    console.log(this.renderFields2)
    let dataPost = {}
    for (let i = 0; i < this.renderFields2.length; i++) {
      dataPost[this.renderFields2[i].key] = this.renderFields2[i].value
    }
    console.log(dataPost)
    try {
      let response = await axios.post(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/order_status`, dataPost);
      // let response = await axios.post(`http://localhost:1348/v1/api/order_status`, dataPost);
      console.log("response", response)
      if (response.data && response.data.data) {
        this.showSuccess()
        this.onHide('displayBasic3')
      }
      this.getTable({ page_index: this.state.page })
    }
    catch (err) {
      console.log(err.response)
      if (err && err.response && err.response.data && err.response.data.message)
        this.showError(err.response.data.message)
      else
        this.showError()
    }
  }

  updateFile = async (name, rowData) => {
    let dataPost = rowData
    for (let i = 0; i < this.renderFields2.length; i++) {
      dataPost[this.renderFields2[i].key] = this.renderFields2[i].value
    }
    console.log(dataPost)
    try {
      let response = await axios.put(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/order_status`, dataPost);
      // let response = await axios.put(`http://localhost:1348/v1/api/order_status`, dataPost);
      console.log("response", response)
      if (response.data && response.data.data) {
        this.showSuccess()
        this.onHide('displayBasic2')
      }
      this.getTable({ page_index: this.state.page })
    }
    catch (err) {
      console.log(err.response)
      if (err && err.response && err.response.data && err.response.data.message)
        this.showError(err.response.data.message)
      else
        this.showError()
    }
  }


  renderFooter1(name) {
    return (
      <div>
        <Button label="Hủy bỏ" icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-danger" />
        <Button label="Xác nhận" icon="pi pi-check" onClick={() => this.createFile(name)} autoFocus />
      </div>
    );
  }
  renderFooter2(name, rowData) {
    return (
      <div>
        <Button label="Hủy bỏ" icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-danger" />
        <Button label="Xác nhận" icon="pi pi-check" onClick={() => this.updateFile(name, rowData)} autoFocus />
      </div>
    );
  }

  openModalCreate = () => {
    this.onClick('displayBasic3', null)
    this.renderFields2[0].value=1
    this.renderFields2[1].value=''
    this.renderFields2[2].value=''
  }
  actionBodyTemplate2 = (rowData) => {
    return (
      <div>

        <Button onClick={() => {
          console.log("row", rowData)
          this.onClick2('displayBasic2', null, rowData)
        }} type="button" icon="pi pi-fw pi-user-edit">
        </Button>
        <Dialog header={`CẬP NHẬT THÔNG TIN : ${this.state.dialog.name}`} visible={this.state.displayBasic2} style={{ width: '50vw' }}
          footer={this.renderFooter2('displayBasic2', this.state.dialog)}
          onHide={() => this.onHide('displayBasic2')}>
          <Input renderFields={this.renderFields2}></Input>
        </Dialog>
      </div>
    );
  }

  actionBodyTemplate1 = (rowData) => {
    rowData.success=!rowData.deleted
    return (
      <div>
        <InputSwitch checked={rowData.success} onChange={async (e) => {
          console.log("rowData", rowData)
          console.log(e)
          // rowData.deleted=e.value?1:0
          let dataPost = {
            ...rowData,
            deleted: e.value ? 0 : 1
          }
          try {
            let response = await axios.put(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/order_status`, dataPost);
            // let response = await axios.put(`http://localhost:1348/v1/api/order_status`, dataPost);
            console.log("response", response)
            if (response.data && response.data.data) {
              this.showSuccess()
            }
            this.getTable({ page_index: this.state.page })
          }
          // let response = await axios.post(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/order_status`,dataPost);
          catch (err) {
            console.log(err.response)
            if (err && err.response && err.response.data && err.response.data.message)
              this.showError(err.response.data.message)
            else
              this.showError()
          }
          this.setState({ change: !this.state.change })
        }}
        />
      </div>
    );
  }


  render() {
    const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
    const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;
    return this.state.loading ? (
      <div>
        <Toast ref={(el) => this.toast = el} />

        <Search createNew={this.openModalCreate} renderFields={this.renderFields} onSearch={this.getTable}></Search>
        <Dialog header={`Tạo điểm ĐKX mới`} visible={this.state.displayBasic3} style={{ width: '50vw' }}
          footer={this.renderFooter1('displayBasic3', this.state.dialog)}
          onHide={() => this.onHide('displayBasic3')}>
          <Input renderFields={this.renderFields2}></Input>
        </Dialog>
        <DataTable value={this.state.data}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rows={10}
          paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}>
          {/* <Column  field="number_turn_code" header="Lần nhập liệu" sortable></Column> */}
          <Column field="order_id" header="Thứ tự"></Column>
          <Column field="name" header="Tên"></Column>
          <Column field="alias" header="Mã định danh"></Column>
          <Column onRowEditSave={(e) => { console.log(e) }} body={this.actionBodyTemplate1} header="Khóa/Mở"></Column>
          <Column onRowEditSave={(e) => { console.log(e) }} body={this.actionBodyTemplate2} header="Sửa"></Column>
        </DataTable>
        <Paginator first={this.state.first}
          rows={10}
          totalRecords={this.state.total}
          onPageChange={this.onPageChange}
          template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"></Paginator>
      </div>
    ) : <i className="pi pi-spin pi-spinner" style={{ 'fontSize': '2em' }}></i>
  }

}
export default GetOrderStatus;

