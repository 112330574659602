import React, { Component } from 'react';
import moment from 'moment';
class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = { time: new Date() };

  }
  componentDidMount() {
    this.interval = setInterval(() => this.setState({ time: new Date }), 1000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    let time = this.state.time
    return (
      <div className="p-grid vertical-container">
        <div className="p-col" style={{"text-align": "center","font-size":"2rem"}}>
        <em>Ngày {time.getDate()} tháng {time.getMonth() + 1} năm {time.getFullYear()}  {time.getHours()}:{String(time.getMinutes()).padStart(2,'0')}:{String(time.getSeconds()).padStart(2,'0')}</em>
        </div>
      </div>
    )
  }

}
export default Dashboard;

