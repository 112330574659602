import React from 'react';
// import { InputText } from 'primereact/inputtext';
import { useHistory } from 'react-router-dom';

export const AppTopbar = (props) => {
    let history = useHistory();
    const redirect = () => {
        window.location.replace(`${process.env.REACT_APP_AUTH_BASE_URL}/logout`)
        localStorage.clear()
    }
    return (
        <div className="layout-topbar clearfix">
            <button type="button" className="p-link layout-menu-button" onClick={props.onToggleMenu}>
                <span className="pi pi-bars" />
            </button>
            <div className="layout-topbar-icons">
                {/* <span className="layout-topbar-search">
                    <InputText type="text" placeholder="Search" />
                    <span className="layout-topbar-search-icon pi pi-search" />
                </span> */}
                {/* <button type="button" className="p-link">
                    <span className="layout-topbar-item-text">Events</span>
                    <span className="layout-topbar-icon pi pi-calendar" />
                    <span className="layout-topbar-badge">5</span>
                </button> */}
                <button type="button" className="p-link" onClick={redirect}>
                    <span className="layout-topbar-item-text">Logout</span>
                    <span className="layout-topbar-icon pi pi-sign-out" />
                </button>
                {/* <button type="button" className="p-link">
                    <span className="layout-topbar-item-text">User</span>
                    <span className="layout-topbar-icon pi pi-user" />
                </button> */}
            </div>
        </div>
    );
}
