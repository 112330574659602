import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Route } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
// import logo from './logo.svg';
import axios from './services/axios'

import { AppTopbar } from './default/AppTopbar';
// import { AppFooter } from './default/AppFooter';
import { AppMenu } from './default/AppMenu';
import { AppProfile } from './default/AppProfile';
// import { AppConfig } from './default/AppConfig';

import Default from './container/default/default';
import ChangePassword from './container/profile/changePassword';
import Dashboard from './container/dashboard/Dashboard';
import GetLabelMain from './container/labelMain/GetLabelMain';
import GetLabelOther from './container/labelOther/GetLabelOther';
import GetLabelSide from './container/labelSide/GetLabelSide';
import GetLabelSize from './container/labelSize/GetLabelSize';
import GetProductStatus from './container/productStatus/GetProductStatus';
import GetOrderStatus from './container/orderStatus/GetOrderStatus';

// import Report1 from './container/report/Report1';
// import Report2 from './container/report/Report2';
// import AddCavet from './container/cavet/AddCavet';
// import AddCavet1 from './container/cavet/AddCavet1';
// import AddCavet2 from './container/cavet/AddCavet2';
// import AddCavet3 from './container/cavet/AddCavet3';
// import AddCavet4 from './container/cavet/AddCavet4';
// import AddCavet5 from './container/cavet/AddCavet5';
// import AddCavet6 from './container/cavet/AddCavet6';
// import AddCavet8 from './container/cavet/AddCavet8';
// import AddCavet9 from './container/cavet/AddCavet9';
// import AddCavet10 from './container/cavet/AddCavet10';
// import AddCavet11 from './container/cavet/AddCavet11';
// import AddCavet12 from './container/cavet/AddCavet12';
// import AddCavet13 from './container/cavet/AddCavet13';


// import GetCavet from './container/cavet/GetCavet';
// import GetCavetAll from './container/cavet/GetCavetAll';
// import GetCavetHistory from './container/cavet/GetCavetHistory';
// import GetCavetNumber from './container/cavet/GetCavetNumber';
// import GetFile from './container/file/GetFile';
// import GetFile1 from './container/file/GetFile1';
// import GetFile2 from './container/file/GetFile2';
// import GetFileHistory from './container/file/GetFileHistory';

// import GetLocation from './container/location/GetLocation';
// import GetDocumentType from './container/document_type/GetDocumentType';
// import GetCavetType from './container/cavet/GetCavetType';
// import GetDynamicStatus from './container/dynamicStatus/GetDynamicStatus';
// import GetStaticStatus from './container/staticStatus/GetStaticStatus';
// import GetColor from './container/color/GetColor';
// import GetCarType from './container/carType/GetCarType';
// import GetUnit from './container/unit/GetUnit';

// import EditStatusFile from './container/file/EditStatusFile';
// import EditStatusFile1 from './container/file/EditStatusFile1';
// import Test from './container/file/test';
import AddOrder from './container/order/AddOrder';
import EditOrder from './container/order/EditOrder';
import AddProduct from './container/product/AddProduct';
import GetOrder from './container/order/GetOrder';
import GetOrderType2 from './container/order/GetOrderType2';



import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/primereact.css';


import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
// import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import './layout/flags/flags.css';
import './layout/layout.scss';
import './layout/_variables.scss';
import './home.scss';
import 'react-block-ui/style.css';
import GetPermission from './container/permission/GetPermission';
import EditProduct from './container/product/EditProduct';

const Home =  (props) => {
  const [layoutMode, setLayoutMode] = useState('static');
  const [layoutColorMode, setLayoutColorMode] = useState('dark')
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [inputStyle, setInputStyle] = useState('outlined');
  const [ripple, setRipple] = useState(false);
  const sidebar = useRef();
  let menuClick = false;
  let menu =props.menu

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, 'body-overflow-hidden');
    }
    else {
      removeClass(document.body, 'body-overflow-hidden');
    }
  }, [mobileMenuActive]);

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  }



  const onLayoutModeChange = (mode) => {
    setLayoutMode(mode)
  }

  const onColorModeChange = (mode) => {
    setLayoutColorMode(mode)
  }

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
    menuClick = false;
  }

  const onToggleMenu = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === 'overlay') {
        setOverlayMenuActive(prevState => !prevState);
      }
      else if (layoutMode === 'static') {
        setStaticMenuInactive(prevState => !prevState);
      }
    }
    else {
      setMobileMenuActive(prevState => !prevState);
    }
    event.preventDefault();
  }

  const onSidebarClick = () => {
    menuClick = true;
  }

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  }
  let checkPermission = (value) => {
    if (!!!value) {
      return true
    }
    let user = localStorage.getItem("user")
    try {
      let dataOut = false
      user = JSON.parse(user)
      let permission = user.permission
      let permissionAr = permission.split(",")
      for (let i = 0; i < permissionAr.length; i++) {
        if (permissionAr[i].trim() === value || permissionAr[i].trim() === "*") {
          dataOut = true
          break
        }
      }
      return dataOut
    }
    catch (err) {
      return false
    }
  }
  let checkMenuRecursive = (menuData,) => {
    let dataOut = []
    for (let i = 0; i < menuData.length; i++) {
      if (checkPermission(menuData[i].permission)) {
        if (menuData[i].items) {
          menuData[i].items = checkMenuRecursive(menuData[i].items)
        }
        dataOut.push(menuData[i])
      }
    }
    return dataOut
  }



  // const menu = [
  //   { label: 'Tổng quan', icon: 'pi pi-fw pi-home', to: '/dashboard', permission: "TQ" },
  //   { label: 'Tìm kiếm', icon: 'pi pi-fw pi-clone', to: '/get-cavet-history', permission: "TKTC" },
  //   { label: 'In Lần Nhập', icon: 'pi pi-fw pi-comment', to: '/get-cavet', permission: "DS" },
  //   // {
  //     // label: 'Nhập liệu thông tin', icon: 'pi pi-fw pi-folder', permission: "NLTT",
  //     // items: [
  //       // {label:'Trình ký',icon:'pi pi-fw pi-upload',to:'/add-cavet'},
  //       { label: 'Trình ký Cấp Đội', iconDetail:'1.', to: '/add-cavet1', permission: "TKCD" },
  //       { label: 'Trình ký Cấp Phòng', iconDetail:'2.', to: '/add-cavet2', permission: "TKCP" },
  //       { label: 'Trả hồ sơ', iconDetail:'3.', to: '/add-cavet3', permission: "THSL" },
  //       { label: 'Bàn giao thẻ', iconDetail:'4.', to: '/add-cavet4',permission:"TTVQ" },
  //       // {label:'File',icon:'pi pi-fw pi-file',to:'/file'},
  //       { label: 'Trả thẻ cho dân', iconDetail:'5.', to: '/add-cavet5',permission:"TTCD" },
  //       { label: 'Trả thẻ bưu điện', iconDetail:'6.', to: '/add-cavet9',permission:"TTBD" },
  //       { label: 'Trả biển số', iconDetail:'7.', to: '/add-cavet10',permission:"TBS" },


  //       { label: 'Hồ sơ lưu kho', iconDetail:'8.',  to: '/edit-status-file' , permission: "NK"},
  //       { label: 'Khai thác hồ sơ', iconDetail:'9.',  to: '/edit-status-file1', permission: "XK" },
  //       { label: 'Giao, nhận khác', iconDetail:'10.',  to: '/add-cavet8', permission: "GNK" },
  //       { label: 'Bàn giao biển số', iconDetail:'11.',  to: '/add-cavet11', permission: "BGBS" },
  //       { label: 'Đề xuất dập biển số', iconDetail:'12.',  to: '/add-cavet12', permission: "DXDBS" },
  //       { label: 'Giao thẻ Bưu Điện', iconDetail:'13.',  to: '/add-cavet13', permission: "GTBD" },


  //       { label: 'Điều chỉnh thông tin', icon: 'pi pi-fw pi-file', to: '/add-cavet6',permission:"DCTT" },
  //     // ],
  //     // to: '/add-cavet'
  //   // },
  //   { label: 'Xuất báo cáo số lượng nhập theo Điểm ĐKX', icon: 'pi pi-fw pi-bookmark', to: '/get-cavet-number', permission: "XDLN" },
  //   { label: 'In barcode BSX theo lần nhập', icon: 'pi pi-fw pi-tablet', to: '/get-cavet-all', permission: "IBTC" },

  //   { label: 'Kiểm tra hồ sơ trong / ngoài kho', icon: 'pi pi-fw pi-desktop', to: '/get-file', permission: "TKHC" },
  //   { label: 'Báo cáo hồ sơ trong kho', icon: 'pi pi-fw pi-calendar-plus', to: '/get-file2', permission: "TKHC" },
  //   // { label: 'In Barcode hồ sơ', icon: 'pi pi-fw pi-folder-open', to: '/get-file1', permission: "IBHS" },
  //   { label: 'Lịch sử hồ sơ', icon: 'pi pi-fw pi-folder-open', to: '/get-file-history', permission: "IBHS" },
  //   {
  //     label: 'Cài đặt', icon: 'pi pi-fw pi-cog',permission: "CD",
  //     items: [
  //       { label: 'Điểm đăng ký xe', icon: 'pi pi-fw pi-image', to: '/get-location',permission: "KV" },
  //       { label: 'Trạng thái hồ sơ của kho', icon: 'pi pi-fw pi-eye', to: '/get-dynamic-status' ,permission: "TTD"},
  //       { label: 'Kiểm tra hồ sơ trong hoặc ngoài kho', icon: 'pi pi-fw pi-eye-slash', to: '/get-static-status' ,permission: "TTK"},
  //       { label: 'Trạng thái hồ sơ trình ký', icon: 'pi pi-fw pi-eye-slash', to: '/get-cavet-type' ,permission: "TTCV"},
  //       { label: 'Màu sắc', icon: 'pi pi-fw pi-palette', to: '/get-color' ,permission: "MS"},
  //       { label: 'Loại xe', icon: 'pi pi-fw pi-palette', to: '/get-car-type' ,permission: "LX"},
  //       { label: 'Loại tài liệu', icon: 'pi pi-fw pi-image', to: '/get-document-type',permission: "TL" },  
  //       { label: 'Phân quyền', icon: 'pi pi-fw pi-palette', to: '/get-permission' ,permission: "PQ"},
  //       { label: 'Đổi mật khẩu', icon: 'pi pi-fw pi-user', to: '/change-password', permission: "IBHS" },
  //     ],
  //     // to: '/add-cavet'
  //   },
  //   // {
  //   //     label: 'UI Kit', icon: 'pi pi-fw pi-sitemap',
  //   //     items: [
  //   //         { label: 'Form Layout', icon: 'pi pi-fw pi-id-card', to: '/formlayout' },
  //   //         { label: 'Input', icon: 'pi pi-fw pi-check-square', to: '/input' },
  //   //         { label: 'Float Label', icon: 'pi pi-fw pi-bookmark', to: '/floatlabel' },
  //   //         { label: 'Button', icon: 'pi pi-fw pi-mobile', to: '/button' },
  //   //         { label: 'Table', icon: 'pi pi-fw pi-table', to: '/table' },
  //   //         { label: 'List', icon: 'pi pi-fw pi-list', to: '/list' },
  //   //         { label: 'Tree', icon: 'pi pi-fw pi-share-alt', to: '/tree' },
  //   //         { label: 'Panel', icon: 'pi pi-fw pi-tablet', to: '/panel' },
  //   //         { label: 'Overlay', icon: 'pi pi-fw pi-clone', to: '/overlay' },
  //   //         { label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/menu' },
  //   //         { label: 'Message', icon: 'pi pi-fw pi-comment', to: '/messages' },
  //   //         { label: 'File', icon: 'pi pi-fw pi-file', to: '/file' },
  //   //         { label: 'Chart', icon: 'pi pi-fw pi-chart-bar', to: '/chart' },
  //   //         { label: 'Misc', icon: 'pi pi-fw pi-circle-off', to: '/misc' },
  //   //     ]
  //   // },
  //   // {
  //   //     label: 'Utilities', icon: 'pi pi-fw pi-globe',
  //   //     items: [
  //   //         { label: 'Display', icon: 'pi pi-fw pi-desktop', to: '/display' },
  //   //         { label: 'Elevation', icon: 'pi pi-fw pi-external-link', to: '/elevation' },
  //   //         { label: 'Flexbox', icon: 'pi pi-fw pi-directions', to: '/flexbox' },
  //   //         { label: 'Icons', icon: 'pi pi-fw pi-search', to: '/icons' },
  //   //         { label: 'Grid System', icon: 'pi pi-fw pi-th-large', to: '/grid' },
  //   //         { label: 'Spacing', icon: 'pi pi-fw pi-arrow-right', to: '/spacing' },
  //   //         { label: 'Typography', icon: 'pi pi-fw pi-align-center', to: '/typography' },
  //   //         { label: 'Text', icon: 'pi pi-fw pi-pencil', to: '/text' },
  //   //     ]
  //   // },
  //   // {
  //   //     label: 'Pages', icon: 'pi pi-fw pi-clone',
  //   //     items: [
  //   //         { label: 'Crud', icon: 'pi pi-fw pi-user-edit', to: '/crud' },
  //   //         { label: 'Calendar', icon: 'pi pi-fw pi-calendar-plus', to: '/calendar' },
  //   //         { label: 'Empty Page', icon: 'pi pi-fw pi-circle-off', to: '/empty' }
  //   //     ]
  //   // },
  //   // {
  //   //     label: 'Menu Hierarchy', icon: 'pi pi-fw pi-search',
  //   //     items: [
  //   //         {
  //   //             label: 'Submenu 1', icon: 'pi pi-fw pi-bookmark',
  //   //             items: [
  //   //                 {
  //   //                     label: 'Submenu 1.1', icon: 'pi pi-fw pi-bookmark',
  //   //                     items: [
  //   //                         { label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark' },
  //   //                         { label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark' },
  //   //                         { label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark' },
  //   //                     ]
  //   //                 },
  //   //                 {
  //   //                     label: 'Submenu 1.2', icon: 'pi pi-fw pi-bookmark',
  //   //                     items: [
  //   //                         { label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark' },
  //   //                         { label: 'Submenu 1.2.2', icon: 'pi pi-fw pi-bookmark' }
  //   //                     ]
  //   //                 },
  //   //             ]
  //   //         },
  //   //         {
  //   //             label: 'Submenu 2', icon: 'pi pi-fw pi-bookmark',
  //   //             items: [
  //   //                 {
  //   //                     label: 'Submenu 2.1', icon: 'pi pi-fw pi-bookmark',
  //   //                     items: [
  //   //                         { label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-bookmark' },
  //   //                         { label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-bookmark' },
  //   //                         { label: 'Submenu 2.1.3', icon: 'pi pi-fw pi-bookmark' },
  //   //                     ]
  //   //                 },
  //   //                 {
  //   //                     label: 'Submenu 2.2', icon: 'pi pi-fw pi-bookmark',
  //   //                     items: [
  //   //                         { label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-bookmark' },
  //   //                         { label: 'Submenu 2.2.2', icon: 'pi pi-fw pi-bookmark' }
  //   //                     ]
  //   //                 }
  //   //             ]
  //   //         }
  //   //     ]
  //   // },
  //   // { label: 'Documentation', icon: 'pi pi-fw pi-question', command: () => { window.location = "#/documentation" } },
  //   // { label: 'View Source', icon: 'pi pi-fw pi-search', command: () => { window.location = "https://github.com/primefaces/sigma-react" } }
  // ];

  const addClass = (element, className) => {
    if (element.classList)
      element.classList.add(className);
    else
      element.className += ' ' + className;
  }

  const removeClass = (element, className) => {
    if (element.classList)
      element.classList.remove(className);
    else
      element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
  }

  const isDesktop = () => {
    return window.innerWidth > 1024;
  }

  const isSidebarVisible = () => {
    if (isDesktop()) {
      if (layoutMode === 'static')
        return !staticMenuInactive;
      else if (layoutMode === 'overlay')
        return overlayMenuActive;
      else
        return true;
    }

    return true;
  }

  // const logo = layoutColorMode === 'dark' ? 'assets/layout/images/loho' : 'assets/layout/images/logo.svg';

  const wrapperClass = classNames('layout-wrapper', {
    'layout-overlay': layoutMode === 'overlay',
    'layout-static': layoutMode === 'static',
    'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
    'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
    'layout-mobile-sidebar-active': mobileMenuActive,
    'p-input-filled': inputStyle === 'filled',
    'p-ripple-disabled': ripple === false
  });

  const sidebarClassName = classNames('layout-sidebar', {
    'layout-sidebar-dark': layoutColorMode === 'dark',
    'layout-sidebar-light': layoutColorMode === 'light'
  });

  return (
    <div className={wrapperClass} onClick={onWrapperClick}>
      <AppTopbar onToggleMenu={onToggleMenu} />

      <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={isSidebarVisible()} unmountOnExit>
        <div ref={sidebar} className={sidebarClassName} onClick={onSidebarClick}>
          {/* <div className="layout-logo">
                        <img alt="Logo" src={logo} />
                    </div> */}
          <AppProfile />
          <AppMenu model={checkMenuRecursive(menu)} onMenuItemClick={onMenuItemClick} />
        </div>
      </CSSTransition>

      {/* <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} /> */}

      <div className="layout-main">
        <Route path="/change-password" component={ChangePassword} />
        <Route path="/" exact component={Default} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/add/order" component={AddOrder} />
        <Route path="/add/order_by_id/:id" component={EditOrder} />
        <Route path="/add/product/:order_id" component={AddProduct} />
        <Route path="/edit/product/:product_id" component={EditProduct} />
        <Route path="/get/order" component={GetOrder} />
        <Route path="/get/order_type_2" component={GetOrderType2} />
        <Route path="/get_label_main" component={GetLabelMain} />
        <Route path="/get_label_other" component={GetLabelOther} />
        <Route path="/get_label_side" component={GetLabelSide} />
        <Route path="/get_label_size" component={GetLabelSize} />
        <Route path="/get_product_status" component={GetProductStatus} />
        <Route path="/get_order_status" component={GetOrderStatus} />

        <Route path="/get-permission" component={GetPermission} />

        {/* <Route path="/input" component={InputDemo} />
                <Route path="/floatlabel" component={FloatLabelDemo} />
                <Route path="/button" component={ButtonDemo} />
                <Route path="/table" component={TableDemo} />
                <Route path="/list" component={ListDemo} />
                <Route path="/tree" component={TreeDemo} />
                <Route path="/panel" component={PanelDemo} />
                <Route path="/overlay" component={OverlayDemo} />
                <Route path="/menu" component={MenuDemo} />
                <Route path="/messages" component={MessagesDemo} />
                <Route path="/file" component={FileDemo} />
                <Route path="/chart" component={ChartDemo} />
                <Route path="/misc" component={MiscDemo} />
                <Route path="/display" component={DisplayDemo} />
                <Route path="/elevation" component={ElevationDemo} />
                <Route path="/flexbox" component={FlexBoxDemo} />
                <Route path="/icons" component={IconsDemo} />
                <Route path="/grid" component={GridDemo} />
                <Route path="/spacing" component={SpacingDemo} />
                <Route path="/typography" component={TypographyDemo} />
                <Route path="/text" component={TextDemo} />
                <Route path="/calendar" component={Calendar} />
                <Route path="/crud" component={Crud} />
                <Route path="/empty" component={EmptyPage} />
                <Route path="/documentation" component={Documentation} />  */}
      </div>

      {/* <AppFooter /> */}

    </div>
  );

}

export default Home