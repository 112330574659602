import React, { Component } from 'react';
import FullCalendar from '@fullcalendar/react'

import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';


import axios from '../../services/axios'
import _ from 'lodash'
import moment from 'moment';

class DashBoard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      resources: []
    }
    // this.getTable = this.getTable.bind(this);
  }
  headerToolbar = {
    left: 'prev,next today',
    center: 'title',
    right: 'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth,resourceTimelineYear,dayGridMonth,timeGridWeek,timeGridDay'
  }
  // async componentDidMount() {
  //   let response = await axios.get(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/product/timeline`)
  //   if (response && response.data && response.data.data) {
  //     this.setState({ data: data })
  //   }
  // }
  customButtons = {
    prevButton: {
      text: '',
      icon: "chevron-left",
      click: function (time) {
      },
    },
  }
  getCalendarData = (fetchInfo, successCallback, failureCallback) => {
    console.log("fetchInfo", fetchInfo)
    // console.log("successCallback",successCallback)
    // console.log("failureCallback",failureCallback)
    // this.getDataCallendar(fetchInfo)
    let resources = this.state.resources
    let params = {
      date_from: fetchInfo.start,
      date_to: fetchInfo.end,
    }
    // axios.get(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/product/timeline`, {
    //   params: {
    //     ...params
    //   }
    // })
    // if (response && response.data && response.data.data) {
    //   let data = _.map(response.data.data.data, ite => {
    //     return {
    //       id: ite.id,
    //       title: ite.name,
    //       // start: moment(ite.delivery_from).format('x'),
    //       start: ite.delivery_from,
    //       end: ite.delivery_to,
    //       url: `/edit/product/${ite.id}`
    //       // end: moment(ite.delivery_to).format('x'),
    //     }
    //   })
    //   successCallback(data);
    // }


    axios.get(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/order/timeline`, {
      params: {
        ...params
      }
    })
      .then(responseResource => {
        if (responseResource && responseResource.data && responseResource.data.data) {
          resources = _.map(responseResource.data.data.data, ite => {
            let children = []
            _.map(ite.products, iteS => {
              if (iteS.id)
                children.push({
                  id: `product_` + iteS.id,
                  title: iteS.name,
                  start: iteS.delivery_from,
                  end: iteS.delivery_to,
                  url: `/edit/product/${iteS.id}`
                })
            })
            return {
              id: ite.id,
              title: ite.customer_name,
              start: ite.delivery_from,
              end: ite.delivery_to,
              eventColor: "purple",
              url: `/add/order_by_id//${ite.id}`,
              children: children
            }
          })
          let events = []
          _.map(resources, ite => {
            events.push({
              id: ite.id,
              title: ite.title,
              start: ite.start,
              end: ite.end,
              url: `/add/order_by_id/${ite.id}`,
              resourceId: ite.id,
              eventColor: "purple"
            })
            _.map(ite.children, iteS => {
              events.push({
                ...iteS,
                resourceId: iteS.id,

              })
            })
          })
          // console.log("events", events)
          // console.log("resources", resources)
          successCallback(events);
          this.setState({ resources: resources })
        }
      })
      .catch(err => {

      })

  }


  render() {
    return (
      <div className="">
        <FullCalendar
          resourceAreaColumns={[
            {
              field: 'title',
              headerContent: 'Sản phẩm'
            }]}
          customButtons={this.customButtons}
          headerToolbar={this.headerToolbar}
          plugins={[interactionPlugin, dayGridPlugin, listPlugin, timeGridPlugin, resourceTimelinePlugin]}
          initialView={["resourceTimelineYear"]}
          height={"auto"}
          events={this.getCalendarData}
          resources={this.state.resources}
          locale={"vi"}
          dayHeaderFormat={{
            // weekday: 'narrow',
            //  month: 'numeric',
            day: 'numeric',
            // omitCommas: true 
          }}
          slotLabelFormat={{
            // weekday: 'narrow',
            month: '2-digit',
            day: '2-digit',
            // omitCommas: true 
          }}
        />
      </div>
    )
  }


}


export default DashBoard;
