import _ from "lodash"

export default function uncheckRequired(renderField) {
    let dataAfterCheckRequired = _.map(renderField, ite => {
        return {
            ...ite,
            "inputClassName": ite.inputClassName ? ite.inputClassName.replace("huynt-input-required", "") : ""
        }

    })
    return dataAfterCheckRequired
}