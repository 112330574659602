
import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import Select from 'react-select'
import _ from 'lodash'

class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      change: false,
      selectedOption: null
    }
  }
  renderComponent(x, ind) {
    let component = (<div></div>)
    switch (x.component) {
      case "date":
        component = (<Calendar dateFormat="dd/mm/yy" value={x.value} onChange={(e) => {
          this.props.renderFields[ind].value = e.value
          // this.props.renderFields[ind].value=e.value
        }}></Calendar>)
        break;
      case "dateRange":
        component = (<Calendar selectionMode="range" dateFormat="dd/mm/yy" value={x.value} onChange={(e) => {
          this.props.renderFields[ind].value = e.value
          if (x.onChange) {
            x.onChange(e)
          }
          // this.props.renderFields[ind].value=e.value
        }}></Calendar>)
        break
      case "select":
        component = (<Dropdown value={x.value} options={x.options} onChange={(e) => {
          this.props.renderFields[ind].value = e.value
          x.value = e.value
          this.setState({ change: !this.state.change })
        }} optionLabel={x.optionLabel} optionValue={x.optionValue}
        ></Dropdown>)
        // code block
        break;
      case "multi-select":
        component = (<MultiSelect value={x.value} options={x.options} onChange={(e) => {
          this.props.renderFields[ind].value = e.value
          x.value = e.value
          this.setState({ change: !this.state.change })
        }} optionLabel={x.optionLabel} optionValue={x.optionValue}
        ></MultiSelect>)
        // code block
        break;
      case "select-filter":
        component = (<Select
          onChange={async (e) => {
            this.props.renderFields[ind].value = e.value
            x.value = e.value
            this.setState({ change: !this.state.change })
            if (x.changeFunction) {
              await x.changeFunction(e.value)
            }
          }}
          options={x.options.map((item, i) => {
            return { id: i, value: item[x.optionValue], label: item[x.optionLabel] }
          })}
          // onKeyDown={(event)=>{x.onKeyDown(event)}}
          onInputChange={(value) => x.onInputChange(value)}
          isDisabled={x.disabled ? x.disabled : false}
        ></Select>)
        // code block
        break;
      case "text":
        component = (<InputText value={x.value} onKeyPress={x.onKeyPress ? x.onKeyPress : null}
          onChange={(e) => {
            this.props.renderFields[ind].value = e.target.value
            if (x.changeFunction) {
              x.changeFunction(e.target.value)
            }
            this.setState({ change: !this.state.change })
          }
          } />)
        break;
      case "text-upper":
        component = (<InputText
          value={x.value}
          onKeyPress={x.onKeyPress ? x.onKeyPress : null}
          onChange={(e) => {
            e.target.value = e.target.value.toUpperCase()
            this.props.renderFields[ind].value = e.target.value
            if (x.changeFunction) {
              x.changeFunction(e.target.value)
            }
            this.setState({ change: !this.state.change })
          }}
          placeholder={x.placeholder ? x.placeholder : ""}
        />)
        break;

      case "random-text":
        component = (<InputText disabled value={x.value} onChange={(e) => {
          this.props.renderFields[ind].value = e.value
          this.setState({ change: !this.state.change })
        }
        } />)
        break;
      case "label":
        component = (<span>{x.value}</span>)
        break;
      case "button-export":
        component = (<Button label="Xuất dữ liệu" className="p-button-success p-button-text" />
        )
        break;
      default:
        <div></div>
    }
    // if(x.component==="date"){
    //   return 
    // }
    return component
  }
  onSearchHandler = () => {
    let data = {}
    for (let i = 0; i < this.props.renderFields.length; i++) {
      if (this.props.renderFields[i].component === "dateRange") {
        console.log(this.props.renderFields[i].from)
        data[this.props.renderFields[i].from] =  this.props.renderFields[i].value[0]
        data[this.props.renderFields[i].to] =  this.props.renderFields[i].value[1]

      }
      else { data[this.props.renderFields[i].key] = this.props.renderFields[i].value }
    }
    this.props.onSearch(data);
  }
  render() {
    return (
      <div className="p-grid vertical-container">
        {this.props.renderFields.map((x, ind) => {
          return (
            <div className={"p-col-" + x.width}>
              <div className="p-field">
                <label className={x.labelClassName ? `p-col ${x.labelClassName}` : 'p-col'}>{x.label}</label>
                <div className="p-col">
                  {
                    this.renderComponent(x, ind)
                  }
                </div>
              </div>
            </div>
          )
        })}
        <div className="p-col-2">
          <div className="p-field" style={{ "lineHeight": "5.8" }}>
            <Button className="p-button-success" label="Lọc" onClick={this.onSearchHandler} style={{ "alignSelf": "center" }} />
          </div>
        </div>
        {this.props.exportExcel ? <div className="p-col-2">
          <div className="p-field" style={{ "lineHeight": "5.8" }}>
            <Button label={this.props.exportExcelLabel ? this.props.exportExcelLabel : "Xuất dữ liệu"} className={this.props.exportExcelClass ? this.props.exportExcelClass : "p-button-success p-button-text"} onClick={this.props.exportExcel} />
          </div>
        </div> : null}
        {this.props.createNew ? <div className="p-col-2 p-align-end">
          <div className="p-field" style={{ "lineHeight": "6.0" }}>
            <Button label="Tạo mới" onClick={this.props.createNew} />
          </div>
        </div> : null}
        {this.props.actionCustom ? <div className="p-col-2 p-align-end">
          <div className="p-field" style={{ "lineHeight": "6.0" }}>
            <Button label={this.props.actionCustom.label} onClick={this.props.actionCustom.onClick} />
          </div>
        </div> : null}
        {this.props.actionCustom2 ? <div className="p-col-2 p-align-end">
          <div className="p-field" style={{ "lineHeight": "6.0" }}>
            <Button label={this.props.actionCustom2.label} onClick={this.props.actionCustom2.onClick} />
          </div>
        </div> : null}
      </div >
    )
  }
}


export default Search;
