import axios from "axios"

// let axiosMiddleware = {
//   get: (url, params) => {
//     return new Promise((resolve, reject) => {
//       axios.get(url, {
//         ...params
//       }).then(result => {
//         return resolve(result)
//       }).catch(err => {
//         console.log("err.response.", Object.keys(err), err.response)
//         if (err.response && err.response.status === 403) {
//           window.location.replace(`${process.env.REACT_APP_AUTH_BASE_URL}/login`)
//         }
//         return reject(err)
//       })
//     })
//   },
//   post: (url, params) => {
//     return new Promise((resolve, reject) => {
//       axios.post(url, {
//         ...params
//       }).then(result => {
//         return resolve(result)
//       }).catch(err => {
//         if (err.response && err.response.status === 403) {
//           window.location.replace(`${process.env.REACT_APP_AUTH_BASE_URL}/login`)
//         }
//         return reject(err)
//       })
//     })
//   },
//   put: (url, params) => {
//     return new Promise((resolve, reject) => {
//       axios.put(url, {
//         ...params
//       }).then(result => {
//         return resolve(result)
//       }).catch(err => {
//         if (err.response && err.response.status === 403) {
//           window.location.replace(`${process.env.REACT_APP_AUTH_BASE_URL}/login`)
//         }
//         return reject(err)
//       })
//     })
//   },
// }
export default {
  get: (url, params) => {
    return new Promise((resolve, reject) => {
      axios.get(url, {
        ...params
      }).then(result => {
        return resolve(result)
      }).catch(err => {
        console.log("err.response.", Object.keys(err), err.response)
        if (err.response && err.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_AUTH_BASE_URL}/login`)
        }
        return reject(err)
      })
    })
  },
  post: (url, params) => {
    return new Promise((resolve, reject) => {
      axios.post(url, {
        ...params
      }).then(result => {
        return resolve(result)
      }).catch(err => {
        if (err.response && err.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_AUTH_BASE_URL}/login`)
        }
        return reject(err)
      })
    })
  },
  put: (url, params) => {
    return new Promise((resolve, reject) => {
      axios.put(url, {
        ...params
      }).then(result => {
        return resolve(result)
      }).catch(err => {
        if (err.response && err.response.status === 403) {
          window.location.replace(`${process.env.REACT_APP_AUTH_BASE_URL}/login`)
        }
        return reject(err)
      })
    })
  },
}