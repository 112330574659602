import React, { Component, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import Select from 'react-select'
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { FileUpload } from 'primereact/fileupload';

// import { v4 as uuidv4 } from 'uuid';
// import MD5 from "crypto-js/md5"
// import moment from "moment"
import _ from "lodash"

class Input extends Component {
  constructor(props) {
    super(props)
    this.state = {
      change: false
    }
  }

  renderComponent(x, ind) {
    // let [dropdownValue, setDropdownValue] = useState({
    //   value: "",
    //   options: x.options
    // })
    let component = (<div></div>)
    switch (x.component) {
      case "date":
        component = (<Calendar value={x.value} onChange={(e) => {
          this.props.renderFields[ind].value = e.value
          // this.props.renderFields[ind].value=e.value
        }}></Calendar>)
        break;
      case "select":
        component = (<Dropdown
          appendTo={document.body}
          value={x.value}
          options={x.options}
          onChange={(e) => {
            this.props.renderFields[ind].value = e.value
            x.value = e.value
            if (this.props.change) this.props.change()
            this.setState({ change: !this.state.change })
          }}
          optionLabel={x.optionLabel}
          optionValue={x.optionValue}
          disabled={x.disabled ? x.disabled : false}
        ></Dropdown>)
        // code block
        break;
      case "select-filter":
        component = (<Select
          onChange={async (e) => {
            this.props.renderFields[ind].value = e.value
            x.value = e.value
            this.setState({ change: !this.state.change })
            if (x.changeFunction) {
              await x.changeFunction(e.value)
            }
          }}
          options={x.options.map((item, i) => {
            return { id: i, value: item[x.optionValue], label: item[x.optionLabel] }
          })}
          // onKeyDown={(event)=>{x.onKeyDown(event)}}
          onInputChange={(value) => x.onInputChange(value)}
          isDisabled={x.disabled ? x.disabled : false}
        ></Select>)
        // code block
        break;
      case "text-number":
        component = (
          <InputNumber
            mode="decimal"
            useGrouping={false}
            className={x.className ? x.className : null}
            type={x.type ? x.type : null}
            value={x.value}
            onKeyPress={x.onKeyPress ? x.onKeyPress : null}
            onChange={(e) => {
              this.props.renderFields[ind].value = e.value
              this.setState({ change: !this.state.change })
            }}
            disabled={x.disabled ? true : false}
          // className={x.validateOnly}
          />)
        break;
      case "text":
        component = (
          <InputText
            className={x.className ? x.className : null}
            type={x.type ? x.type : null}
            value={x.value}
            onKeyPress={x.onKeyPress ? x.onKeyPress : null}
            onChange={(e) => {
              this.props.renderFields[ind].value = e.target.value
              this.setState({ change: !this.state.change })
            }}
            disabled={x.disabled ? true : false}
          // className={x.validateOnly}
          />)
        break;
      case "text-upper":
        component = (
          <InputText
            value={x.value}
            onKeyPress={x.onKeyPress ? x.onKeyPress : null}
            onChange={(e) => {
              e.target.value = e.target.value.toUpperCase()
              this.props.renderFields[ind].value = e.target.value
              this.setState({ change: !this.state.change })
            }}
            disabled={x.disabled ? true : false}
          // className={x.validateOnly}
          />)
        break;

      case "random-text":
        component = (<InputText disabled value={x.value} onChange={(e) => {
          this.props.renderFields[ind].value = e.value
          this.setState({ change: !this.state.change })
        }
        } />)
        break;
      case "label":
        component = (<span
          className={x.className ? `p-col ${x.className}` : 'p-col'}
        >{x.value}</span>)
        break;
      case "textarea":
        component = (<InputTextarea autoResize={x.autoResize} value={x.value} onKeyPress={x.onKeyPress ? x.onKeyPress : null} onChange={(e) => {
          this.props.renderFields[ind].value = e.target.value
          this.setState({ change: !this.state.change })
        }}
          disabled={x.disabled ? true : false} />)
        break;
      case "button":
        component = (
          <Button
            className={x.className ? `p-col ${x.className}` : 'p-col'}
            label={x.buttonLabel}
            onClick={x.onClick} style={{ "align-self": "center" }}
            disabled={x.disabled ? true : false}
          />)
        break;
      case "upload":
        component = (
          <FileUpload
            className={x.className ? `p-col ${x.className}` : 'p-col'}
            chooseLabel={x.buttonLabel}
            mode={x.mode}
            style={{ "align-self": "center" }}
            disabled={x.disabled ? true : false}
            accept={x.accept}
            onSelect={x.onSelect}
            onUpload={x.onUpload}
          />)
        break;
      default:
        <div></div>
    }
    return component
  }

  onSaveHandler = () => {
    let data = {}
    for (let i = 0; i < this.props.renderFields.length; i++)
      data[this.props.renderFields[i].key] = this.props.renderFields[i].value
    this.props.onSave(data);
  }

  render() {
    return (
      <div className="p-grid">
        {this.props.renderFields.map((x, ind) => {
          return (
            <div className={`${x.colClassName?x.colClassName:""} `+"p-col-" + x.width}>
              <div className="p-field">
                <label className={x.labelClassName ? `p-col ${x.labelClassName}` : 'p-col'}>{x.label}</label>
                <div className={x.nonCol ? "" : "p-col"}>
                  {
                    this.renderComponent(x, ind)
                  }
                </div>
              </div>
            </div>
          )
        })}
        {/* <div className="p-col-2">
          <div className="p-field" style={{ "line-height": "4.8" }}>
            <Button className="p-button-success" label="Tìm" onClick={this.onSaveHandler} style={{ "align-self": "center" }} />
          </div>
        </div> */}
      </div>
    )
  }
}

export default Input;
