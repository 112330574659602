import React, { Component } from 'react';
import Search from '../../components/Search';
import axios from '../../services/axios'
import _ from 'lodash'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import moment from 'moment'
import { Toast } from 'primereact/toast';
import { Paginator } from 'primereact/paginator';
import { Dialog } from 'primereact/dialog';
import Input from '../../components/Input';
import { InputSwitch } from 'primereact/inputswitch';

class GetPermission extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      total: 0,
      loading: true,
      displayBasic: false,
      displayBasic2: false,
      displayBasic3: false,
      displayModal: false,
      displayConfirmation: false,
      displayMaximizable: false,
      displayPosition: false,
      position: 'center',
      dialog: {},
      change: true,
      first: 0,
      permissionNote: [
        // { code: "*", permission: "Toàn quyền" },
        // { code: "TQ", permission: "Tổng quan" },
        // { code: "DS", permission: "Danh sách" },
        // { code: "NLTT", permission: "Nhập liệu thông tin" },
        // { code: "THSM", permission: "Tạo hồ sơ mới" },
        // { code: "NHL", permission: "Nhập hàng loạt" },
        // { code: "TD", permission: "Trả dân" },
        // { code: "HS", permission: "Hồ sơ" },
        // { code: "TS", permission: "Tra soát dữ liệu nhập" },
        // { code: "LSHS", permission: "Lịch sử hồ sơ" },
        // { code: "NK", permission: "Nhập kho" },
        // { code: "XK", permission: "Xuất kho" },
        // { code: "XDLN", permission: "Xuất dữ liệu nhập" },
        // { code: "CD", permission: "Cài đặt" },
        // { code: "KV", permission: "Cài đặt Điểm ĐKX" },
        // { code: "TTD", permission: "Cài đặt Trạng thái hồ sơ" },
        // { code: "TTT", permission: "Cài đặt Trạng thái kho" },
        // { code: "MS", permission: "Cài đặt Màu sắc" },
        // { code: "PQ", permission: "Cài đặt Phân quyền" },
      ]
    }
    this.getTable = this.getTable.bind(this);
  }
  renderFields = [
    {
      component: "text",
      options: [],
      value: '',
      label: "Email tài khoản",
      width: 3,
      key: "username",
      onKeyPress: (event) => {
        if (event.charCode === 13) {
          console.log("ok vua an enter")
          console.log(this.renderFields)
        }
      }
    }]
  renderFields2 = [
    {
      component: "text",
      options: [],
      value: '',
      label: "Email",
      width: 4,
      key: "username",
      disabled: true
    },
    {
      component: "textarea",
      options: [],
      value: '',
      label: "Quyền",
      width: 8,
      key: "permission",
      autoResize: true,
      disabled: true
    },
  ]

  renderFields3 = [
    {
      component: "text",
      options: [],
      value: '',
      label: "Email",
      width: 6,
      key: "username",
    },
    {
      component: "text",
      options: [],
      value: '',
      label: "Mật khẩu",
      width: 3,
      key: "password",
      autoResize: true,
      type: "password"
      // validateOnly:true
    },
    {
      component: "text",
      options: [],
      value: '',
      label: "Xác nhận mật khẩu",
      width: 3,
      key: "validate_password",
      autoResize: true,
      type: "password"
      // validateOnly:true
    },
    {
      component: "textarea",
      options: [],
      value: '',
      label: "Quyền",
      width: 12,
      key: "permission",
      autoResize: true,
      disabled: true
    },

  ]

  async componentDidMount() {
    let data = {}
    for (let i = 0; i < this.renderFields.length; i++) {
      data[this.renderFields[i].key] = this.renderFields[i].value
    }
    await Promise.all([this.getPermission(), this.getTable(data)])
    this.setState({ loading: true })
    return true
  }
  showSuccess() {
    this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Thành công', life: 3000 });
  }

  showError(detail) {
    this.toast.show({ severity: 'error', summary: 'Error Message', detail: detail ? detail : 'Có lỗi xảy ra', life: 3000 });
  }


  async getTable(data) {

    let response = await axios.get(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/user`, {
      params: {
        ...data
      }
    });
    if (response && response.data && response.data.data) {
      this.setState({ data: response.data.data.data, total: response.data.data.total })
      console.log(this.state.data)
    }
  }

  async getPermission() {
    let response = await axios.get(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/menu`);
    let data = [{ code: "*", permission: "Toàn quyền" }]
    if (response && response.data && response.data.data) {
      _.map(response.data.data.data, ite => {
        if (ite.permission) {
          data.push({
            code: ite.permission,
            permission: ite.label,
          })
        }
        if (!_.isEmpty(ite.items)) {
          _.map(ite.items, iteS => {
            if (iteS.permission) {
              data.push({
                code: iteS.permission,
                permission: `->${iteS.label}`
              })
            }
          })
        }
      }
      )
      // data.unshift({ code: "*", permission: "Toàn quyền" })
      this.setState({ permissionNote: data })
    }
  }

  onPageChange = (event) => {
    console.log(event.page)
    this.setState({
      first: event.first,
      // rows:event.rows
    });
    let data = {}
    for (let i = 0; i < this.renderFields.length; i++) {
      data[this.renderFields[i].key] = this.renderFields[i].value
    }
    data.page_index = event.page + 1
    this.getTable(data)
  }
  onClick(name, position) {
    let state = {
      [`${name}`]: true,
    };

    if (position) {
      state = {
        ...state,
        position,
      }
    }
    let permissionNote = _.map(this.state.permissionNote, ite => {
      return {
        ...ite,
        deleted: 1
      }
    })
    this.setState({ ...state, permissionNote: permissionNote });
  }
  onHide(name) {
    this.setState({
      [`${name}`]: false
    });
  }
  onClick2(name, position, rowData) {
    let state = {
      [`${name}`]: true,
      dialog: rowData
    };

    if (position) {
      state = {
        ...state,
        position,
        dialog: rowData
      }
    }
    this.renderFields2[0].value = rowData.username
    this.renderFields2[1].value = rowData.permission
    let permission = []
    if (rowData.permission)
      permission = rowData.permission.split(",")
    let permissionNote = _.map(this.state.permissionNote, ite => {
      let check = permission.includes(ite.code)
      return {
        ...ite,
        deleted: check ? 0 : 1
      }
    })
    console.log("permissionNote", permissionNote)

    this.setState({ ...state, permissionNote: permissionNote });
  }

  createFile = async (name) => {
    // console.log(this.renderFields3)
    let dataPost = {}
    for (let i = 0; i < this.renderFields3.length; i++) {
      dataPost[this.renderFields3[i].key] = this.renderFields3[i].value
    }
    // console.log(dataPost)
    if (dataPost.validate_password !== dataPost.password) {
      this.renderFields3[1].className = 'p-invalid'
      this.renderFields3[2].className = 'p-invalid'
      this.setState({ change: !this.state.change })
      this.showError("Thông tin mật khẩu không chính xác.Vui lòng thử lại")
    }
    try {

      let response = await axios.post(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/user`, dataPost);
      // let response = await axios.post(`http://localhost:1348/v1/api/user`, dataPost);
      console.log("response", response)
      if (response.data && response.data.data) {
        this.showSuccess()
        this.onHide('displayBasic3')
      }
      this.getTable()
    }
    catch (err) {
      console.log(err.response)
      this.showError("Vui lòng điền đầy đủ thông tin và thử lại")
      //   if (err && err.response && err.response.data && err.response.data.message)
      //     this.showError(err.response.data.message)
      //   else
      //     this.showError()
    }
  }

  updateFile = async (name, rowData) => {
    let dataPost = rowData
    for (let i = 0; i < this.renderFields2.length; i++) {
      dataPost[this.renderFields2[i].key] = this.renderFields2[i].value
    }
    console.log(dataPost)
    try {
      let response = await axios.put(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/user`, dataPost);
      // let response = await axios.put(`http://localhost:1348/v1/api/user`, dataPost);
      console.log("response", response)
      if (response.data && response.data.data) {
        this.showSuccess()
        this.onHide('displayBasic2')
      }
      this.getTable()
    }
    catch (err) {
      console.log(err.response)
      if (err && err.response && err.response.data && err.response.data.message)
        this.showError(err.response.data.message)
      else
        this.showError()
    }
  }


  renderFooter1(name) {
    return (
      <div>
        <Button label="Hủy bỏ" icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-danger" />
        <Button label="Xác nhận" icon="pi pi-check" onClick={() => this.createFile(name)} autoFocus />
      </div>
    );
  }
  renderFooter2(name, rowData) {
    return (
      <div>
        <Button label="Hủy bỏ" icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-danger" />
        <Button label="Xác nhận" icon="pi pi-check" onClick={() => this.updateFile(name, rowData)} autoFocus />
      </div>
    );
  }

  openModalCreate = () => {
    this.onClick('displayBasic3', null)
    this.renderFields2[0].value = ''
    this.renderFields2[1].value = ''
  }
  actionBodyTemplate2 = (rowData) => {
    console.log("action", this.state.permissionNote)
    return (
      <div>

        <Button onClick={() => {
          console.log("row", rowData)
          this.onClick2('displayBasic2', null, rowData)
        }} type="button" icon="pi pi-fw pi-user-edit">
        </Button>
        <Dialog header={`CẬP NHẬT THÔNG TIN : ${this.state.dialog.username}`} visible={this.state.displayBasic2} style={{ width: '50vw' }}
          footer={this.renderFooter2('displayBasic2', this.state.dialog)}
          onHide={() => this.onHide('displayBasic2')}>
          <Input renderFields={this.renderFields2}></Input>
          <div>Quyền được ngăn bởi dấu ","</div>
          <DataTable value={this.state.permissionNote}>
            <Column field="code" header="Mã định danh"></Column>
            <Column field="permission" header="Chức năng"></Column>
            <Column onRowEditSave={(e) => { console.log(e) }} body={this.actionBodyTemplate4} header="Khóa/Mở"></Column>
          </DataTable>
        </Dialog>
      </div>
    );
  }

  actionBodyTemplate3 = (rowData) => {
    rowData.success = !rowData.deleted
    return (
      <div>
        <InputSwitch checked={rowData.success} onChange={async (e) => {
          // console.log("rowData", rowData)
          // console.log(e)
          rowData.deleted = e.value ? 0 : 1
          if (e.value) {
            if (this.renderFields3[3].value) {
              this.renderFields3[3].value += `,${rowData.code}`
            }
            else {
              this.renderFields3[3].value = rowData.code
            }
          }
          else {
            if (this.renderFields3[3].value.split(",").length > 1) {
              this.renderFields3[3].value = this.renderFields3[3].value.replace(`,${rowData.code}`, "")
              this.renderFields3[3].value = this.renderFields3[3].value.replace(`${rowData.code},`, "")
            }
            else {
              this.renderFields3[3].value = this.renderFields3[3].value.replace(rowData.code, "")
            }
          }
          this.setState({ change: !this.state.change })
        }}
        />
      </div>
    );
  }
  actionBodyTemplate4 = (rowData) => {
    rowData.success = !rowData.deleted
    return (
      <div>
        <InputSwitch checked={rowData.success} onChange={async (e) => {
          // console.log("rowData", rowData)
          // console.log(e)
          rowData.deleted = e.value ? 0 : 1
          if (e.value) {
            if (this.renderFields2[1].value) {
              this.renderFields2[1].value += `,${rowData.code}`
            }
            else {
              this.renderFields2[1].value = rowData.code
            }
          }
          else {
            if (this.renderFields2[1].value.split(",").length > 1) {
              this.renderFields2[1].value = this.renderFields2[1].value.replace(`,${rowData.code}`, "")
              this.renderFields2[1].value = this.renderFields2[1].value.replace(`${rowData.code},`, "")
            }
            else {
              this.renderFields2[1].value = this.renderFields2[1].value.replace(rowData.code, "")
            }
          }
          this.setState({ change: !this.state.change })
        }}
        />
      </div>
    );
  }

  render() {
    const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
    const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;
    return this.state.loading ? (
      <div>
        <Toast ref={(el) => this.toast = el} />

        <Search createNew={this.openModalCreate} renderFields={this.renderFields} onSearch={this.getTable}></Search>
        <Dialog header={`Tạo tài khoản mới`} visible={this.state.displayBasic3} style={{ width: '50vw' }}
          footer={this.renderFooter1('displayBasic3', this.state.dialog)}
          onHide={() => this.onHide('displayBasic3')}>
          <Input renderFields={this.renderFields3}></Input>
          <div>Quyền được ngăn bởi dấu ","</div>
          <DataTable value={this.state.permissionNote}>
            <Column field="code" header="Mã định danh"></Column>
            <Column field="permission" header="Chức năng"></Column>
            <Column onRowEditSave={(e) => { console.log(e) }} body={this.actionBodyTemplate3} header="Khóa/Mở"></Column>
          </DataTable>
        </Dialog>
        <DataTable value={this.state.data}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rows={10}
          paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}>
          {/* <Column  field="number_turn_code" header="Lần nhập liệu" sortable></Column> */}
          <Column field="id" header="Mã"></Column>
          <Column field="username" header="Email tài khoản"></Column>
          <Column field="permission" header="Quyền"></Column>
          {/* <Column onRowEditSave={(e) => { console.log(e) }} body={this.actionBodyTemplate1} header="Mở/khoá"></Column> */}
          <Column onRowEditSave={(e) => { console.log(e) }} body={this.actionBodyTemplate2} header="Sửa"></Column>
        </DataTable>
        <Paginator first={this.state.first}
          rows={10}
          totalRecords={this.state.total}
          onPageChange={this.onPageChange}
          template="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"></Paginator>
      </div>
    ) : <i className="pi pi-spin pi-spinner" style={{ 'fontSize': '2em' }}></i>
  }

}
export default GetPermission;

