import _ from "lodash"

export default {
    set: (key, value) => {

        return localStorage.setItem(key, JSON.stringify(value));
    },
    get: (key) => {

        const val = localStorage.getItem(key)

        return _.isUndefined(val) ? "" : JSON.parse(val)
    },
    clear: () => {
        return localStorage.clear()
    },
    length: () => {
        return localStorage.length
    },
    remove: (key) => {
        return localStorage.removeItem(key)
    }
}