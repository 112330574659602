import React, { Component, useRef } from 'react';
import InputType2 from '../../components/InputType2';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Search from '../../components/Search';
import axios from '../../services/axios'
import _ from 'lodash'
import moment from "moment"
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import BlockUi from 'react-block-ui';
import localStorageServices from '../../services/localStorageServices';
import { Calendar } from 'primereact/calendar';
import { useParams } from "react-router-dom";

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
class EditOrder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      order_id: null,
      loading: false,
      data: [],
      productDeleted: [],
      dataShow: [],
      disabledSave: false,
      disabledExport: false,
      disabledProduct: true,
      displayBasic3: false,
      config: {},
      dialog: {},
      change: false,
      blocking: false,
      disabledInput: false,
      intervalId: null,
      checkFalse: [],
      changeColor: true,
      date: [],
      renderFields: []
    }
    this.save = this.save.bind(this);
    this.showSuccess = this.showSuccess.bind(this);

  }

  randomNumber() {
    let user = localStorage.getItem("user")
    user = JSON.parse(user)
    let name = user.name
    let string = ''
    for (let i = 0; i < 3; i++) {
      string = string + name.charCodeAt(i)
    }
    // console.log("1",NIL_UUID)
    //  let UUID = uuidv4().replace(/[xy]/g, function(c) {
    //   var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    //   return v.toString(16);
    // })
    return string + moment(new Date()).unix()
  }
  // checkCavet = async (name) => {
  //   let patt = new RegExp("^[A-Z_0-9]+$");
  //   let res = patt.test(name);
  //   return res
  // }

  renderFields = [
    {
      width: 3,
    },
    {
      component: "text-search",
      label: "Tên KH",
      width: 6,
      key: "customer_name",
      required: true,
      inputClassName: "p-col-9",
      field: "name",
      completeMethod: async (event) => {
        let data = await axios.get(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/kiot/customer?name=${event.query}`);
        if (!_.isEmpty(data.data.data)) {
          this.renderFields[1].suggestions = data.data.data
        }
        else { this.renderFields[1].suggestions = [] }
        this.setState({ renderFields: this.renderFields })
      }
    },
    {
      width: 3,
    },
    {
      width: 3,
    },
    {
      component: "text-number-integer",
      label: "Cọc",
      width: 6,
      key: "deposit_money",
      inputClassName: "p-col-9",
    },
    {
      width: 3,
    },
    {
      width: 3,
    },
    {
      component: "date",
      label: "Ngày cọc",
      width: 6,
      key: "deposit_at",
      inputClassName: "p-col-9",
    },
    {
      width: 3,
    },

    {
      width: 3,
    },
    {
      component: "textarea",
      label: "Ghi chú",
      width: 6,
      key: "note",
      inputClassName: "p-col-9",
    },
    {
      width: 3,
    },

    {
      width: 3,
    },
    {
      component: "textarea",
      label: "Lịch sử chăm sóc",
      width: 6,
      key: "customer_care_note",
      inputClassName: "p-col-9",
    },
    {
      width: 3,
    },

    {
      width: 3,
    },
    {
      component: "text",
      label: "Tags",
      width: 6,
      key: "tag",
      inputClassName: "p-col-9",
    },
    {
      width: 3,
    },

    {
      width: 3,
    },
    {
      label: "Trạng thái",
      component: "select",
      options: [],
      value: 1,
      width: 6,
      key: "order_status_id",
      inputClassName: "p-col-9",
      optionLabel: "name",
      optionValue: "id"
    },
    {
      width: 3,
    },

    {
      width: 3,
    },

    // {
    //   component: "dateRange",
    //   label: "Thời gian giao hàng dự kiến",
    //   width: 6,
    //   key: "delivery_time",
    //   inputClassName: "p-col-9",
    // },
    // {
    //   width: 3,
    // },
  ]


  showSuccess() {
    this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Thành công', life: 3000 });
  }

  showError() {
    this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Có lỗi xảy ra', life: 3000 });
  }
  disabledInput(check) {
    this.renderFields.map(ite => {
      if (!ite.requireDisabled) {
        ite.disabled = check
      }
      return ite
    })
    this.setState({ disabledInput: check })
  }

  async save() {
    let data = this.state.renderFields
    let date = this.state.date

    this.setState({ blocking: true })
    let dataPost = {}
    _.map(data, ite => {
      if (ite.key) {
        dataPost[ite.key] = ite.value
      }
    })
    dataPost = {
      ...dataPost,
      delivery_from: date[0],
      delivery_to: date[1],
      product_id_deleted: this.state.productDeleted
    }
    // console.log("dataPost", dataPost)
    try {
      // console.log("dataPost", JSON.stringify(dataPost))
      this.disabledInput(true)
      this.setState({
        disabledSave: true,
      })
      let { id } = this.props.params;
      if (id) {
        dataPost.id = id
        let response = await axios.put(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/order`, dataPost);
        if (response.data && response.data.data) {
          this.setState({ order_id: response.data.data.id })
          this.showSuccess()
          this.setState({
            disabledExport: false,
            blocking: false
          })
        }
      }
      else {
        let response = await axios.post(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/order`, dataPost);
        if (response.data && response.data.data) {
          this.showSuccess()
          this.setState({
            disabledExport: false,
            blocking: false
          })
        }
      }
    }
    catch (err) {
      // console.log("err", err)
      this.disabledInput(false)
      this.setState({
        disabledSave: false,
        disabledExport: false,
        blocking: false
      })
      this.showError()
    }
  }

  export = async () => {
    let { id } = this.props.params;
    this.setState({ blocking: true })
    let response = await axios.post(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/kiot/order`, { order_id: id });
    if (response.data && response.data.data) {
      this.showSuccess()
      this.setState({
        disabledExport: false,
        blocking: false
      })
    }
  }

  createProduct = () => {
    window.open(`/add/product/${this.state.order_id}`);
  }

  getDataById = async (order_id) => {
    if (order_id) {
      let response = await axios.get(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/order_by_id?order_id=${order_id}`)
      if (response && response.data && response.data.data) {
        let data = response.data.data
        data = data.data
        data = data[0]
        let date = []
        let renderFields = this.state.renderFields
        if (data.delivery_from) {
          date[0] = new Date(data.delivery_from)
        }
        if (data.delivery_to) {
          date[1] = new Date(data.delivery_to)
        }
        console.log(renderFields)
        _.map(data, (value, key) => {
          let index = _.findIndex(renderFields, { key: key })
          if (index > -1) {
            if (renderFields[index].component === "date" && value) {
              renderFields[index].value = new Date(value)
              console.log(renderFields[index].value)
            }
            else {
              renderFields[index].value = value
            }
          }
        })
        let disabledExport = false

        console.log(renderFields)
        this.setState({ data: data.products, renderFields: renderFields, date: date, disabledExport: disabledExport })
      }
    }
  }


  async componentDidMount() {
    //TODO:
    let response = await axios.get(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/order/config`)
    if (response && response.data && response.data.data) {
      let data = response.data.data
      this.renderFields[19].options = data.order_status
      // this.renderFields[19].value = data.order_status[0].id


      this.setState({ config: data })
    }
    let { id } = this.props.params;
    let disabledProduct = this.state.disabledProduct
    if (id) {
      disabledProduct = false
      this.setState({ order_id: id })
    }

    this.setState({ loading: true, renderFields: this.renderFields, disabledProduct: disabledProduct })
    if (id) {
      await this.getDataById(id)
    }
    return true
  }


  actionBodyTemplate = (rowData) => {
    return (
      <div>
        <Button
          icon="pi pi-times"
          className="p-button-rounded p-button-danger"
          onClick={() => {
            let data = this.state.data

            let index = _.findIndex(data, { id: rowData.id })
            let productDeleted = this.state.productDeleted
            productDeleted.push(rowData.id)
            data.splice(index, 1)
            data = _.map(data, (ite, index) => {
              return { ...ite, number: data.length - index }
            })
            this.renderFields[6].value = data.length
            let disabledSave = true
            if (data.length > 0) disabledSave = false
            this.setState({ data: data, disabledSave: disabledSave })
          }}
          disabled={this.state.disabledInput}
        />
        <Button
          icon="pi pi-print"
          // className="p-button-rounded p-button-danger"
          style={{ "margin-left": "5px" }}
          tooltip="In Phiếu In Thêu"
          onClick={() => {
            let { id } = this.props.params;
            window.open(`/printer/order/mau_1?order_id=${id}&product_id=${rowData.id}`);
          }}
          disabled={this.state.disabledInput}
        ></Button>
        <Button
          icon="pi pi-print"
          // className="p-button-rounded p-button-danger"
          style={{ "margin-left": "5px" }}
          tooltip="In Phiếu May - Gia Công"
          onClick={() => {
            let { id } = this.props.params;
            window.open(`/printer/order/mau_2?order_id=${id}&product_id=${rowData.id}`);
          }}
          disabled={this.state.disabledInput}
        ></Button>
        <Button
          icon="pi pi-print"
          // className="p-button-rounded p-button-danger"
          style={{ "margin-left": "5px" }}
          tooltip="In Phiếu Cắt"
          onClick={() => {
            let { id } = this.props.params;
            window.open(`/printer/order/mau_3?order_id=${id}&product_id=${rowData.id}`);
          }}
          disabled={this.state.disabledInput}
        ></Button>
        <Button
          icon="pi pi-print"
          // className="p-button-rounded p-button-danger"
          style={{ "margin-left": "5px" }}
          tooltip="In Phiếu Mẫu"
          onClick={() => {
            let { id } = this.props.params;
            window.open(`/printer/order/mau_4?order_id=${id}&product_id=${rowData.id}`);
          }}
          disabled={this.state.disabledInput}
        ></Button>
      </div>
    );
  }


  idTemplate = (rowData) => {
    return (
      <a href={`/edit/product/${rowData.id}`}>{rowData.id}</a>
    );
  }

  nameTemplate = (rowData) => {
    return (
      <a href={`/edit/product/${rowData.id}`}>{rowData.name}</a>
    );
  }



  render() {
    let check = false
    if (!_.isEmpty(this.state.checkFalse)) {
      check = true
    }
    const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
    const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;
    return this.state.loading ? (
      <div>
        <BlockUi tag="div" blocking={this.state.blocking}>
          <Toast ref={(el) => this.toast = el} />
          <div className="p-grid" style={{ "margin-bottom": "50px" }}>
            <div className="p-col-3">
              <div className="p-field" >
                <Button label="Thêm sản phẩm" disabled={this.state.disabledProduct} onClick={this.createProduct} style={{ "align-self": "center", "margin-top": "6px", "margin-bottom": "6px", "background-color": "#9B51E0", "width": "70%" }} />
              </div>
            </div>
            <div className="p-col-3">
              <div className="p-field" >
                <Button disabled={this.state.disabledExport} label="Đồng bộ sang Kiot" onClick={this.export} style={{ "align-self": "center", "margin-top": "6px", "margin-bottom": "6px", "background-color": "#2F80ED", "width": "70%" }} />
              </div>
            </div>

            <div className="p-col-3">
              <div className="p-field">
                <Button disabled={this.state.disabledSave} className="p-button-success" label="Sửa" onClick={this.save} style={{ "align-self": "center", "margin-top": "6px", "margin-bottom": "6px", "background-color": "#F2994A", "width": "70%" }} />
              </div>
            </div>

            {/* <div className="p-col-3">
              <div className="p-field">
                <Button label="In triển khai thông tin" disabled={this.state.disabledProduct} onClick={() => this.export("mau_1")} style={{ "align-self": "center", "margin-top": "6px", "margin-bottom": "6px", "background-color": "#9B51E0", "width": "70%" }} />
              </div>
            </div> */}
          </div>

          {/* <div className="p-grid" style={{ "margin-bottom": "50px" }}>
            <div className="p-col-3">
              <div className="p-field" >
                <Button label="In triển khai thông tin" disabled={this.state.disabledProduct} onClick={()=>this.export("mau_1")} style={{ "align-self": "center", "margin-top": "6px", "margin-bottom": "6px", "background-color": "#9B51E0", "width": "70%" }} />
              </div>
            </div>
            <div className="p-col-3">
            <div className="p-field" >
                <Button label="In gia công" disabled={this.state.disabledProduct} onClick={()=>this.export("mau_2")} style={{ "align-self": "center", "margin-top": "6px", "margin-bottom": "6px", "background-color": "#9B51E0", "width": "70%" }} />
              </div>
            </div>

            <div className="p-col-3">
            <div className="p-field" >
                <Button label="In gia công" disabled={this.state.disabledProduct} onClick={()=>this.export("mau_3")} style={{ "align-self": "center", "margin-top": "6px", "margin-bottom": "6px", "background-color": "#9B51E0", "width": "70%" }} />
              </div>
            </div>

          </div> */}

          <div className="p-grid">
            <InputType2 renderFields={this.state.renderFields} onSave={this.save}></InputType2>

            <div className={"p-col-6"}>
              <div className="p-grid" style={{ "margin-bottom": "25px" }}>
                <label className={"p-col"} style={{ "text-align": "left", "align-self": "center", "font-weight": "bold" }}>Thời gian giao hàng dự kiến
                </label>
                <div className={"p-col-9"}>
                  <Calendar
                    dateFormat="dd/mm/yy"
                    selectionMode="range" value={this.state.date} onChange={(e) => {
                      this.setState({ date: e.value })
                    }}></Calendar>
                </div>
              </div>
            </div>
          </div>


          <div className="p-grid" >
            <div className="p-col-3"></div>
            <div className="p-col-6">
              <DataTable value={this.state.data} rowClassName={this.state.rowClass} autoLayout={true}
                paginator
                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                rows={100}
                paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}
              >
                <Column style={{ "width": "25px" }} onRowEditSave={(e) => { }} body={this.idTemplate} header="Mã đơn"></Column>
                <Column onRowEditSave={(e) => { }} body={this.nameTemplate} header="Tên sản phẩm"></Column>
                {/* <Column style={{ "width": "38px" }} field="id" header="Id" ></Column> */}
                {/* <Column field="name" header="Tên sản phẩm"></Column> */}
                <Column style={{ "width": "220px" }} onRowEditSave={(e) => { }} body={this.actionBodyTemplate} header="Thao tác"></Column>
              </DataTable>
            </div>

          </div>




        </BlockUi>
      </div>
    ) : <i className="pi pi-spin pi-spinner" style={{ 'fontSize': '2em' }}></i>
  }

}

export default withParams(EditOrder);

