// import logo from './logo.svg';
import './App.css';
// import { useAuth0 } from "@auth0/auth0-react";
import Home from './home.js'

// import PrinterCavet from './container/cavet/PrinterCavet'
// import PrinterCavet2 from './container/cavet/PrinterCavet2'
// import PrinterCavet3 from './container/cavet/PrinterCavet3'
// import PrinterCavet5 from './container/cavet/PrinterCavet5'
// import PrinterCavet8 from './container/cavet/PrinterCavet8'
// import PrinterCavet10 from './container/cavet/PrinterCavet10'
// import PrinterCavet11 from './container/cavet/PrinterCavet11'
// import PrinterCavet12 from './container/cavet/PrinterCavet12'
// import PrinterCavetCode from './container/cavet/PrinterCavetCode'
// import PrinterFile from './container/file/PrinterFile'
// import PrinterFile1 from './container/file/PrinterFile1'
// import PrinterGetFile2 from './container/file/PrinterGetFile2'
import PrinterOrder_Type_1 from './container/order/PrinterOrder_Type_1'
import PrinterOrder_Type_2 from './container/order/PrinterOrder_Type_2'
import PrinterOrder_Type_3 from './container/order/PrinterOrder_Type_3'
import PrinterOrder_Type_4 from './container/order/PrinterOrder_Type_4'
import _ from 'lodash'

import { BrowserRouter, Route, Switch } from 'react-router-dom'
import axios from './services/axios'
import React, { Component } from 'react';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      check: false,
      menu: false
    }
  }

  checkUser = async () => {
    let user = localStorage.getItem('user')
    try {
      if (user && JSON.parse(user)) {
        return true
      }
      let response = await axios.get(`${process.env.REACT_APP_AUTH_BASE_URL}/profile`);
      // console.log("response", response)
      // console.log("process.env.REACT_APP_AUTH_BASE_URL", process.env.REACT_APP_AUTH_BASE_URL)
      if (response && response.data && response.data.sub) {
        localStorage.setItem("user", JSON.stringify(response.data))
        return true
      }
      return false
    }
    catch (err) {
      console.log("err", err)
      return false
    }
  }

  getMenu = async () => {
    try {
      let data = await axios.get(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/menu`);
      data = data.data
      return data
    }
    catch (err) {
      return []
    }
  }
  // const menu = await getMenu()

  useQuery = () => {
    return new URLSearchParams(window.location.search);
  }
  async componentDidMount() {
    try{
      let check = await this.checkUser()
      let menu = await this.getMenu()
      menu=menu.data.data
  
      console.log("check", check)
      this.setState({ check, menu })
      if (!check) return window.location.replace(`${process.env.REACT_APP_AUTH_BASE_URL}/login`)
    }
    catch(err){
      return window.location.replace(`${process.env.REACT_APP_AUTH_BASE_URL}/login`)
    }
  }

  render() {
    let check = this.state.check
    let menu = this.state.menu
    console.log("menu",menu)
    //  check = true
    return check && _.isArray(menu) && (
      <BrowserRouter>
        <Switch>
          {/* <Route path="/printer/cavet-code" component={PrinterCavetCode}></Route>
          <Route path="/printer/cavet2" component={PrinterCavet2}></Route>
          <Route path="/printer/cavet3" component={PrinterCavet3}></Route>
          <Route path="/printer/cavet5" component={PrinterCavet5}></Route>
          <Route path="/printer/cavet8" component={PrinterCavet8}></Route>
          <Route path="/printer/cavet10" component={PrinterCavet10}></Route>
          <Route path="/printer/cavet11" component={PrinterCavet11}></Route>
          <Route path="/printer/cavet12" component={PrinterCavet12}></Route>
          <Route path="/printer/file" component={PrinterFile}></Route>
          <Route path="/printer/file1" component={PrinterFile1}></Route>
          <Route path="/printer/get-file2" component={PrinterGetFile2}></Route> */}
          {/* <Route path="/"><Home /></Route> */}
          <Route path="/printer/order/mau_1" component={PrinterOrder_Type_1}></Route>
          <Route path="/printer/order/mau_2" component={PrinterOrder_Type_2}></Route>
          <Route path="/printer/order/mau_3" component={PrinterOrder_Type_3}></Route>
          <Route path="/printer/order/mau_4" component={PrinterOrder_Type_4}></Route>
          <Route path="/"><Home menu={menu}/></Route>
          </Switch>
      </BrowserRouter >
    )
  }
}


export default App;
