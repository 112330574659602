import React, { Component, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import Select from 'react-select'
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { FileUpload } from 'primereact/fileupload';
import { AutoComplete } from 'primereact/autocomplete';

// import { v4 as uuidv4 } from 'uuid';
// import MD5 from "crypto-js/md5"
// import moment from "moment"
import _ from "lodash"

class Input extends Component {
  constructor(props) {
    super(props)
    this.state = {
      change: false
    }
  }

  renderComponent(x, ind) {
    // let [dropdownValue, setDropdownValue] = useState({
    //   value: "",
    //   options: x.options
    // })
    // let component = (<div></div>)
    switch (x.component) {
      case "date":
        return (
          <Calendar dateFormat="dd/mm/yy" value={x.value} onChange={(e) => {
            this.props.renderFields[ind].value = e.value
            // this.props.renderFields[ind].value=e.value
          }}></Calendar>)
      case "dateRange":
        return <Calendar
          selectionMode="range" value={x.value} onChange={(e) => {
            this.props.renderFields[ind].value = e.value
          }}></Calendar>
      case "select":
        return (<Dropdown
          appendTo={document.body}
          value={x.value}
          options={x.options}
          onChange={(e) => {
            this.props.renderFields[ind].value = e.value
            x.value = e.value
            if (this.props.change) this.props.change()
            this.setState({ change: !this.state.change })
          }}
          optionLabel={x.optionLabel}
          optionValue={x.optionValue}
          disabled={x.disabled ? x.disabled : false}
        ></Dropdown>)
      // code block
      case "select-filter":
        return (<Select
          onChange={async (e) => {
            this.props.renderFields[ind].value = e.value
            x.value = e.value
            this.setState({ change: !this.state.change })
            if (x.changeFunction) {
              await x.changeFunction(e.value)
            }
          }}
          options={x.options.map((item, i) => {
            return { id: i, value: item[x.optionValue], label: item[x.optionLabel] }
          })}
          // onKeyDown={(event)=>{x.onKeyDown(event)}}
          onInputChange={(value) => x.onInputChange(value)}
          isDisabled={x.disabled ? x.disabled : false}
        ></Select>)
      // code block
      case "text-number":
        return (
          <InputNumber
            mode="decimal"
            useGrouping={false}
            className={x.className ? x.className : null}
            type={x.type ? x.type : null}
            value={x.value}
            onKeyPress={x.onKeyPress ? x.onKeyPress : null}
            onChange={(e) => {
              this.props.renderFields[ind].value = e.value
              this.setState({ change: !this.state.change })
            }}
            disabled={x.disabled ? true : false}
          // className={x.validateOnly}
          />)
      case "text-number-integer":
        return (
          <InputNumber
            locale="de-DE"
            value={x.value}
            onKeyPress={x.onKeyPress ? x.onKeyPress : null}
            onChange={(e) => {
              this.props.renderFields[ind].value = e.value
            }}
            disabled={x.disabled ? true : false}
            useGrouping={true}
          // className={x.validateOnly}
          />)
      case "text":
        return (
          <InputText
            className={x.className ? x.className : null}
            type={x.type ? x.type : null}
            value={x.value}
            onKeyPress={x.onKeyPress ? x.onKeyPress : null}
            onChange={(e) => {
              this.props.renderFields[ind].value = e.target.value
              this.setState({ change: !this.state.change })
            }}
            disabled={x.disabled ? true : false}
          // className={x.validateOnly}
          />)
      case "text-search":
        return (
          <AutoComplete
            className={x.className ? x.className : null}
            value={x.value}
            field={x.field}
            suggestions={x.suggestions}
            completeMethod={(event) => {
              setTimeout(() => {
                x.completeMethod(event)
              }, 250);
            }}
            onChange={(e) => {
              console.log(e.value)
              this.props.renderFields[ind].value = e.value
              this.setState({ change: !this.state.change })
            }}
            disabled={x.disabled ? true : false}
          // className={x.validateOnly}
          />)
      case "text-upper":
        return (
          <InputText
            value={x.value}
            onKeyPress={x.onKeyPress ? x.onKeyPress : null}
            onChange={(e) => {
              e.target.value = e.target.value.toUpperCase()
              this.props.renderFields[ind].value = e.target.value
              this.setState({ change: !this.state.change })
            }}
            disabled={x.disabled ? true : false}
          // className={x.validateOnly}
          />)

      case "random-text":
        return (<InputText disabled value={x.value} onChange={(e) => {
          this.props.renderFields[ind].value = e.value
          this.setState({ change: !this.state.change })
        }
        } />)
      case "label":
        return (<span
          className={x.className ? `p-col ${x.className}` : 'p-col'}
        >{x.value}</span>)
      case "textarea":
        return (<InputTextarea autoResize={x.autoResize} value={x.value} onKeyPress={x.onKeyPress ? x.onKeyPress : null} onChange={(e) => {
          this.props.renderFields[ind].value = e.target.value
          this.setState({ change: !this.state.change })
        }}
          disabled={x.disabled ? true : false} />)
      case "button":
        return (
          <Button
            className={x.className ? `p-col ${x.className}` : 'p-col'}
            label={x.buttonLabel}
            onClick={x.onClick} style={{ "align-self": "center" }}
            disabled={x.disabled ? true : false}
          />)
      case "upload":
        return (
          <FileUpload
            className={x.className ? `p-col ${x.className}` : 'p-col'}
            chooseLabel={x.buttonLabel}
            mode={x.mode}
            style={{ "align-self": "center" }}
            disabled={x.disabled ? true : false}
            accept={x.accept}
            onSelect={x.onSelect}
            onUpload={x.onUpload}
          />)
      default:
        return (<div></div>)
    }
  }

  onSaveHandler = () => {
    let data = {}
    for (let i = 0; i < this.props.renderFields.length; i++) {
      if (this.props.renderFields[i].component === "text-search") {
        data[this.props.renderFields[i].key] = this.props.renderFields[i].value[this.props.renderFields[i].field]

      }
      else {
        data[this.props.renderFields[i].key] = this.props.renderFields[i].value
      }
    }
    this.props.onSave(data);
  }

  render() {
    return (
      <>
        {this.props.renderFields.map((x, ind) => {
          return (
            <div className={`${x.colClassName ? x.colClassName : ""} ` + "p-col-" + x.width}>
              <div className="p-grid" style={{ "margin-bottom": "25px" }}>
                <label className={x.labelClassName ? `p-col ${x.labelClassName}` : 'p-col'} style={{ "text-align": "left", "align-self": "center", "font-weight": "bold" }}>{x.label}
                  {x.required ?
                    <span style={{ "text-align": "right", "align-self": "center", "color": "red" }}>*</span>
                    : null}
                </label>

                <div className={x.inputClassName ? `p-col ${x.inputClassName}` : "p-col"}>
                  {
                    this.renderComponent(x, ind)
                  }

                </div>
              </div>
            </div>
          )
        })}
      </>
    )
  }
}

export default Input;
