import React, { Component, useRef } from 'react';
import Input from '../../components/Input';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import axios from '../../services/axios'
import _ from 'lodash'
import moment from "moment"
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import BlockUi from 'react-block-ui';
class ChangePassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      data: [],
      disabledSave: true,
      disabledExport: true,
      config: {},
      change: false,
      blocking: false,
      disabledInput: false,
    }
    this.showSuccess = this.showSuccess.bind(this);

  }



  renderFields = [
    {
      value: '',
      width: 4,
    },
    {
      component: "text",
      options: [],
      value: '',
      label: "Mật khẩu cũ",
      width: 4,
      key: "old_password",
      autoResize: true,
      type: "password"
      // validateOnly:true
    },
    {
      value: '',
      width: 4,
    },
    {
      value: '',
      width: 4,
    },
    {
      component: "text",
      options: [],
      value: '',
      label: "Mật khẩu mới",
      width: 4,
      key: "new_password",
      autoResize: true,
      type: "password"
      // validateOnly:true
    },
    {
      value: '',
      width: 4,
    },
    {
      value: '',
      width: 4,
    },
    {
      component: "text",
      options: [],
      value: '',
      label: "Xác nhận mật khẩu",
      width: 4,
      key: "validate_password",
      autoResize: true,
      type: "password"
      // validateOnly:true
    },
  ]
  showSuccess() {
    this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Thành công', life: 3000 });
  }

  showError(detail) {
    this.toast.show({ severity: 'error', summary: 'Error Message', detail: detail ? detail : 'Có lỗi xảy ra', life: 3000 });
  }


  async componentDidMount() {

    this.setState({ loading: true })
    return true
  }
  save = async () => {
    let dataPost = {}
    for (let i = 0; i < this.renderFields.length; i++) {
      dataPost[this.renderFields[i].key] = this.renderFields[i].value
    }
    // console.log(dataPost)
    if (dataPost.validate_password !== dataPost.new_password) {
      this.renderFields[4].className = 'p-invalid'
      this.renderFields[7].className = 'p-invalid'
      this.setState({ change: !this.state.change })
      return this.showError("Thông tin mật khẩu không chính xác.Vui lòng thử lại")
    }
    try {

      let response = await axios.post(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/user/password`, dataPost);
      // let response = await axios.post(`http://localhost:1348/v1/api/user`, dataPost);
      if (response.data && response.data.success == 1) {
        this.showSuccess()
      }
      _.map(this.renderFields, (ite, ind) => {
        this.renderFields[ind].value = ''
      })
      this.setState({ change: !this.state.change })
    }
    catch (err) {
      // console.log(err.response)
      if (err && err.response && err.response.data && err.response.data.message) {
        if (err.response.data.message === 'Sai mật khẩu')
          this.renderFields[1].className = 'p-invalid'
        else {
          this.renderFields[4].className = 'p-invalid'
          this.renderFields[7].className = 'p-invalid'
        }
        this.setState({ change: !this.state.change })
        return this.showError(err.response.data.message)
      }
      this.showError("Vui lòng điền đầy đủ thông tin và thử lại")
      //   if (err && err.response && err.response.data && err.response.data.message)
      //     this.showError(err.response.data.message)
      //   else
      //     this.showError()
    }
  }

  render() {
    return this.state.loading ? (
      <div>
        <BlockUi tag="div" blocking={this.state.blocking}>
          <Toast ref={(el) => this.toast = el} />
          <Input renderFields={this.renderFields} onSave={this.save}></Input>

          <div className="p-grid p-justify-center">
            <div className="p-col-2">
              <div className="p-field" style={{ "line-height": "4.8" }}>
                <Button className="p-button-success" label="Xác nhận" onClick={this.save} style={{ "align-self": "center" }} />
              </div>
            </div>
          </div>
        </BlockUi>
      </div>
    ) : <i className="pi pi-spin pi-spinner" style={{ 'fontSize': '2em' }}></i>
  }

}

export default ChangePassword;

