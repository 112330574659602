import React, { Component, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import Select from 'react-select'
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { FileUpload } from 'primereact/fileupload';
import { Image } from 'primereact/image';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';

// import { v4 as uuidv4 } from 'uuid';
// import MD5 from "crypto-js/md5"
// import moment from "moment"
import _ from "lodash"

class Input extends Component {
  constructor(props) {
    super(props)
    this.state = {
      change: false,
      displayBasic3: false,
      dialog: {},

    }
  }
  actionBodyTemplate = (rowData, ind, renderFields) => {
    return (
      <div>
        <Button
          icon="pi pi-times"
          className="p-button-rounded p-button-danger"
          onClick={() => {
            let data, index
            data = this.props[renderFields][ind].value
            index = _.findIndex(data, { number: rowData.number })
            data.splice(index, 1)
            data = _.map(data, (ite, index) => {
              return { ...ite, number: data.length - index }
            })
            console.log("data", data)
            this.props[renderFields][ind].value = data
            this.setState({ change: !this.state.change })
          }
          }
        />
      </div>
    );
  }

  columnTemplate = (rowData, ind, renderFields, type, key) => {
    // console.log("type", type)
    // console.log("key", key)
    // console.log(rowData)
    switch (type) {
      case "text-number-integer":
        return (
          <InputNumber
            locale="de-DE"
            value={rowData[key]}
            onChange={(e) => {
              let index = _.findIndex(this.props[renderFields][ind].value, { number: rowData.number })
              this.props[renderFields][ind].value[index][key] = e.value
              this.setState({ change: !this.state.change })
            }}
            useGrouping={true}
          // className={x.validateOnly}
          />)
      case "text":
        return (
          <InputText
            value={rowData[key]}
            onChange={(e) => {
              let index = _.findIndex(this.props[renderFields][ind].value, { number: rowData.number })
              this.props[renderFields][ind].value[index][key] = e.target.value
              this.setState({ change: !this.state.change })
            }}
          // className={x.validateOnly}
          />)
    }
  }
  renderHeader(ind, renderField, labelHeader) {
    return (
      <div className='p-grid flex justify-content-between align-items-center '>
        <div className="p-col-6">
          <strong>{labelHeader}
          </strong>
        </div>
        <div className="p-col-6">
          <Button className="p-button-success" label="Thêm mới" onClick={() => this.addAttribute(ind, renderField)} style={{ "align-self": "center", "margin-top": "6px", "margin-bottom": "6px", "background-color": "#00C008", "width": "100%" }} />
        </div>
      </div>
    )
  }
  addAttribute(ind, renderField) {
    console.log(this.props[renderField][ind].value)
    let dataPush = { number: 1 }
    if (this.props[renderField][ind].value)
      dataPush = { number: this.props[renderField][ind].value.length + 1 }
    else {
      this.props[renderField][ind].value = []
    }
    _.map(this.props[renderField][ind].arrayKey, (ite) => {
      dataPush[ite.key] = ""
    })
    this.props[renderField][ind].value.push(dataPush)
    this.setState({ change: !this.state.change })

  }




  renderComponent(x, ind, renderFields) {
    switch (x.component) {
      case "checkbox":
        return (
          <Checkbox checked={this.props[renderFields][ind].value > 0 ? true : false}
            onChange={(e) => {
              if (e.checked)
                this.props[renderFields][ind].value = 1
              else
                this.props[renderFields][ind].value = 0
              this.setState({ change: !this.state.change })
              // this.props[renderFields][ind].value=e.value
            }}></Checkbox>)
      case "date":
        return (
          <Calendar dateFormat="dd/mm/yy" value={x.value} onChange={(e) => {
            this.props[renderFields][ind].value = e.value
            // this.props[renderFields][ind].value=e.value
          }}></Calendar>)
      case "dateRange":
        return <Calendar
          selectionMode="range" value={x.value} onChange={(e) => {
            this.props[renderFields][ind].value = e.value
          }}></Calendar>
      case "select":
        return (<Dropdown
          appendTo={document.body}
          value={x.value}
          options={x.options}
          onChange={(e) => {
            this.props[renderFields][ind].value = e.value
            x.value = e.value
            if (this.props.change) this.props.change()
            this.setState({ change: !this.state.change })
          }}
          optionLabel={x.optionLabel}
          optionValue={x.optionValue}
          disabled={x.disabled ? x.disabled : false}
        ></Dropdown>)
      // code block
      case "select-filter":
        return (<Select
          onChange={async (e) => {
            this.props[renderFields][ind].value = e.value
            x.value = e.value
            this.setState({ change: !this.state.change })
            if (x.changeFunction) {
              await x.changeFunction(e.value)
            }
          }}
          options={x.options.map((item, i) => {
            return { id: i, value: item[x.optionValue], label: item[x.optionLabel] }
          })}
          // onKeyDown={(event)=>{x.onKeyDown(event)}}
          onInputChange={(value) => x.onInputChange(value)}
          isDisabled={x.disabled ? x.disabled : false}
        ></Select>)
      // code block
      case "text-number":
        return (
          <InputNumber
            mode="decimal"
            useGrouping={false}
            className={x.className ? x.className : null}
            type={x.type ? x.type : null}
            value={x.value}
            onKeyPress={x.onKeyPress ? x.onKeyPress : null}
            onChange={(e) => {
              this.props[renderFields][ind].value = e.value
              this.setState({ change: !this.state.change })
              if (x.onChange) x.onChange(e)
            }}
            disabled={x.disabled ? true : false}
          />)
      case "text-number-integer":
        return (
          <InputNumber
            locale="de-DE"
            value={x.value}
            onKeyPress={x.onKeyPress ? x.onKeyPress : null}
            onChange={(e) => {
              this.props[renderFields][ind].value = e.value
              if (x.onChange) x.onChange(e)
            }}
            disabled={x.disabled ? true : false}
            useGrouping={true}
          // className={x.validateOnly}
          />)
      case "text":
        return (
          <InputText
            className={x.className ? x.className : null}
            type={x.type ? x.type : null}
            value={x.value}
            onKeyPress={x.onKeyPress ? x.onKeyPress : null}
            onChange={(e) => {
              this.props[renderFields][ind].value = e.target.value
              this.setState({ change: !this.state.change })
            }}
            disabled={x.disabled ? true : false}
          // className={x.validateOnly}
          />)
      case "text-upper":
        return (
          <InputText
            value={x.value}
            onKeyPress={x.onKeyPress ? x.onKeyPress : null}
            onChange={(e) => {
              e.target.value = e.target.value.toUpperCase()
              this.props[renderFields][ind].value = e.target.value
              this.setState({ change: !this.state.change })
            }}
            disabled={x.disabled ? true : false}
          // className={x.validateOnly}
          />)

      case "random-text":
        return (<InputText disabled value={x.value} onChange={(e) => {
          this.props[renderFields][ind].value = e.value
          this.setState({ change: !this.state.change })
        }
        } />)
      case "label":
        return (<span
          className={x.className ? `p-col ${x.className}` : 'p-col'}
        >{x.value}</span>)
      case "textarea":
        return (<InputTextarea rows={x.rows || 4} autoResize={x.autoResize} value={x.value} onKeyPress={x.onKeyPress ? x.onKeyPress : null} onChange={(e) => {
          this.props[renderFields][ind].value = e.target.value
          this.setState({ change: !this.state.change })
        }}
          disabled={x.disabled ? true : false} />)
      case "button":
        return (
          <Button
            className={x.className ? `p-col ${x.className}` : 'p-col'}
            label={x.buttonLabel}
            onClick={x.onClick} style={{ "align-self": "center" }}
            disabled={x.disabled ? true : false}
          />)
      case "upload":
        return (
          <FileUpload
            className={x.className ? `p-col ${x.className}` : 'p-col'}
            chooseLabel={x.buttonLabel}
            mode={x.mode}
            style={{ "align-self": "center" }}
            disabled={x.disabled ? true : false}
            accept={x.accept}
            onSelect={x.onSelect}
            onUpload={x.onUpload}
          />)
      case "image":
        return (
          <>
            <div class='p-grid'>
              <FileUpload
                className={x.className ? `p-col ${x.className}` : 'p-col'}
                chooseLabel={x.buttonLabel}
                mode={x.mode}
                style={{ "align-self": "center" }}
                disabled={x.disabled ? true : false}
                accept={x.accept}
                onSelect={x.onSelect}
                onUpload={x.onUpload}
              />
              {x.deleted ?
                <Button
                  className={x.className ? `p-col ${x.className}` : 'p-col'}
                  label={x.buttonLabelDelete || "Xóa"}
                  onClick={x.onClickDelete} style={{ "align-self": "center" }}
                  disabled={x.disabled ? true : false}
                />
                : null}

            </div>
            <div>
              {x.data ?
                x.data.map(image => {
                  return (
                    <Image src={image} alt="Image" width="100" preview />
                  )
                })
                : null}
            </div>
          </>
        )
      case "tableAdd":
        const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
        const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;
        return (
          <>
            <DataTable value={x.value} autoLayout={true} header={() => this.renderHeader(ind, renderFields, x.labelHeader)}
              // paginator
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              rows={100}
            // paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}
            >
              {x.header.map((item, index) => {
                return (
                  <Column
                    style={x.arrayKey[index].style ? x.arrayKey[index].style : null}
                    onRowEditSave={(e) => { }} field={x.arrayKey[index].key} body={(rowData) => this.columnTemplate(rowData, ind, renderFields, x.arrayKey[index].type, x.arrayKey[index].key)} header={item}></Column>
                )
              })}
              <Column style={{ "max-width": "50px", "width": "50px" }} onRowEditSave={(e) => { }} body={(rowData) => this.actionBodyTemplate(rowData, ind, renderFields)} header="Xóa"></Column>
            </DataTable>
          </>
        )

      default:
        return (<div></div>)
    }
  }

  onSaveHandler = () => {
    let data = {}
    for (let i = 0; i < this.props.renderFieldsLeft.length; i++)
      data[this.props.renderFieldsLeft[i].key] = this.props.renderFieldsLeft[i].value
    for (let i = 0; i < this.props.renderFieldsRight.length; i++)
      data[this.props.renderFieldsRight[i].key] = this.props.renderFieldsRight[i].value
    this.props.onSave(data);
  }

  render() {
    return (
      <>
        <div className={"p-col-6"} style={{ "padding-right": "25px" }}>
          {this.props.renderFieldsLeft.map((x, ind) => {
            return (
              x.width > 0 ? <div className="p-grid" style={{ "margin-bottom": "25px" }}>
                {x.headerLabel ?
                  <label className={'p-col-12'} style={{ "text-align": "left", "align-self": "center", "font-weight": "bold", "padding": "7px", "padding-bottom": "25px", "font-size": "18px" }}>{x.headerLabel}</label>
                  : null}
                <label className={x.labelClassName ? `p-col ${x.labelClassName}` : 'p-col'} style={{ "text-align": "left", "align-self": "center", "font-weight": "bold" }}>{x.label}
                  {x.required ?
                    <span style={{ "text-align": "right", "align-self": "center", "color": "red" }}>*</span>
                    : null}
                </label>

                <div className={x.inputClassName ? `p-col ${x.inputClassName}` : "p-col"}>
                  {
                    this.renderComponent(x, ind, "renderFieldsLeft")
                  }

                </div>
              </div> : null)
          })}

        </div>
        <div className={"p-col-6"}>
          {this.props.renderFieldsRight ? this.props.renderFieldsRight.map((x, ind) => {
            return (
              <div className="p-grid" style={{ "margin-bottom": "25px" }}>
                {x.headerLabel ?
                  <label className={'p-col-12'} style={{ "text-align": "left", "align-self": "center", "font-weight": "bold", "padding": "7px", "padding-bottom": "25px", "font-size": "18px" }}>{x.headerLabel}</label>
                  : null}
                <label className={x.labelClassName ? `p-col ${x.labelClassName}` : 'p-col'} style={{ "text-align": "left", "align-self": "center", "font-weight": "bold" }}>{x.label}
                  {x.required ?
                    <span style={{ "text-align": "right", "align-self": "center", "color": "red" }}>*</span>
                    : null}
                </label>

                <div className={x.inputClassName ? `p-col ${x.inputClassName}` : "p-col"}>
                  {
                    this.renderComponent(x, ind, "renderFieldsRight")
                  }

                </div>
              </div>)
          }) : null}

        </div>
      </>
    )
  }
}

export default Input;
