import _ from "lodash"

export default function checkRequired(renderField) {
    let required = false
    let lengthRequired = 0
    let lengthEmpty = 0
    let dataAfterCheckRequired = _.map(renderField, ite => {
        if (ite.required) {
            lengthEmpty++
        }

        if (ite.required) {
            if (ite.value === "" || ite.value === [] || ite.value === {} || ite.value === undefined || ite.value === null) {
                required = true
                lengthRequired++
                return {
                    ...ite,
                    "inputClassName": ite.inputClassName ? ite.inputClassName + " huynt-input-required" : " huynt-input-required"
                }
            }
        }
        return { ...ite }
    })
    return [dataAfterCheckRequired, required, lengthRequired == lengthEmpty ? true : false]
}