import React, { Component } from 'react';
import Search from '../../components/Search';
import axios from '../../services/axios'
import _ from 'lodash'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import moment from 'moment'
import { Toast } from 'primereact/toast';

class GetOrderType2 extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      loading: true,
      date: []
    }
    this.getTable = this.getTable.bind(this);
  }
  renderFields = [
    {
      component: "text",
      label: "Nhập tên/Số điện thoại khách hàng",
      value: "",
      key: "customer_name",
      width: 4
    },


    {
      component: "dateRange",
      label: "Nhập thời gian tạo đơn",
      value: [],
      key: "time",
      width: 4,
      onChange: (e) => {
        this.setState({ date: e.value })
      },
      from: "date_from",
      to: "date_to",
    },
  ]


  showSuccess() {
    this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Thành công', life: 3000 });
  }

  showError(name) {
    this.toast.show({ severity: 'error', summary: 'Error Message', detail: name || 'Có lỗi xảy ra', life: 3000 });
  }


  async componentDidMount() {
    //TODO:
    // let response = await axios.get(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/config`)
    // console.log("renderField", this.renderFields)
    // let response = await axios.get(`http://localhost:1348/v1/api/config`)
    // if (response && response.data && response.data.data) {

    // }
    // let data = {}
    // for (let i = 0; i < this.renderFields.length; i++) {
    //   data[this.renderFields[i].key] = this.renderFields[i].value
    // }
    this.getTable()
    this.setState({ loading: true })
    return true
  }



  async getTable(data) {
    try {
      let response = await axios.get(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/order_group_by_status_id`, {
        params: {
          ...data
        }
      });
      if (response && response.data && response.data.data) {
        // let dataR = _.map(response.data.data.data, ite => {
        //   return { ...ite }
        // })
        this.setState({ data: response.data.data.data })
        console.log(this.state.data)
      }
    }
    catch (err) {
      if (err.response && err.response.data) {
        this.showError(err.response.data.message)
      }
    }

  }
  onKeyPressHandler = (event) => {
    if (event.charCode === 13) {
      let data = {}
      for (let i = 0; i < this.renderFields.length; i++) {
        data[this.renderFields[i].key] = this.renderFields[i].value
      }
      this.getTable(data)
    }
  }
  idTemplate = (rowData) => {
    return (
      <a href={`/add/order_by_id/${rowData.id}`}>{rowData.id}</a>
    );
  }

  productTemplate = (rowData) => {
    return (
      <>
        {
          rowData.product.map(ite => {
            return (<div>{ite.name}<span style={{ "color": "red" }}> X {ite.total}</span></div>)
          })
        }
      </>
    );
  }
  formatTime(time) {
    if (time)
      return moment(time).format("DD/MM/YYYY")
    return
  }

  render() {
    const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
    const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;
    return this.state.loading ? (
      <div onKeyPress={this.onKeyPressHandler} tabIndex="0" className="hidden-outline">
        <Toast ref={(el) => this.toast = el} />
        <Search renderFields={this.renderFields} onSearch={this.getTable} date={this.state.date}></Search>
        <div className='huynt-unlimit'>
          {this.state.data.map((ite, ind) => {
            console.log("ite", ite)
            return (
              <>
                <div className='huynt-table no-padding'>
                  <div className='p-grid huynt-table-header' style={{ "margin": "0px" }}>
                    <b className='p-col-6'>
                      {ite[0].order_status_name}
                    </b>
                    {/* <b className='p-col-6' style={{ "color": ite.order_status_color || "green" }}>
                    </b> */}
                  </div>
                  {ite.map((order) => {
                    console.log("order", order)
                    return (
                      <>
                        {
                          order.product.map((iteS) => {
                            return (
                              <div className='p-grid huynt-table-body'>
                                <div className='p-col-12 huynt-table-text huynt-table-body-header'>
                                  {order.customer_name}
                                </div>
                                <div className='p-col-12' style={{ "height": "171px" }}>
                                  <div className='p-col-12 huynt-table-text huynt-table-text-brown' style={{ "color": "black", "margin-bottom": "15px" }}>
                                    {iteS.name} <span style={{ "color": "red" }}>X {iteS.total}</span> 
                                  </div>
                                  {iteS.note ? <div className='p-col-12 huynt-table-text huynt-table-text-brown' style={{ "color": "black" }}>
                                    Ghi chú:{iteS.note}
                                  </div> : null}

                                </div>
                                <div className='p-col-6 huynt-table-text huynt-table-body-status' style={{ "font-size": "15px", "text-align": "center", "line-height": "1.7" }}>
                                  <span style={{ "padding": "1px", }}>
                                    {iteS.status_name}
                                  </span>
                                </div>
                                <div className='p-col-6' style={{ "color": "blue", "margin-left": "-12px" }}>
                                  <div className='p-col-12 huynt-table-text' style={{ "text-align": "center", "font-weight": "bold" }}>
                                    {this.formatTime(iteS.delivery_from)}
                                  </div>
                                  <div className='p-col-12 huynt-table-text' style={{ "text-align": "center", "font-weight": "bold" }}>
                                    {this.formatTime(iteS.delivery_to)}
                                  </div>
                                </div>

                              </div>
                            )
                          })
                        }
                      </>
                    )
                  })}

                </div>
              </>
            )
          })}

        </div>
        {/* <div className="flexbox-demo">
          <div className="p-d-flex">
            {
              this.state.data.map((ite, ind) => {
                return (
                  <div onClick={() => this.onClick(ind)} className={`p-mr-3 flex-demo-hover ${this.state.data[ind].check ? 'flex-demo-click' : ''}`}>
                    <div>{ite.order_status_name}</div>
                    <div>{ite.customer_name}</div>
                  </div>
                )
              })
            }
          </div>
        </div> */}
      </div >
    ) : <i className="pi pi-spin pi-spinner" style={{ 'fontSize': '2em' }}></i>
  }

}
export default GetOrderType2;

