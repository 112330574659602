import React, { Component } from 'react';
import Search from '../../components/Search';
import axios from '../../services/axios'
import _ from 'lodash'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import moment from 'moment'
import { Toast } from 'primereact/toast';
import { Paginator } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';

class GetOrder extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      loading: true,
      date: [],
      totalRecords: 0,
      first: 0,
      orderStatus: []
    }
    this.getTable = this.getTable.bind(this);
  }
  renderFields = [
    {
      component: "text",
      label: "Nhập tên/Số điện thoại khách hàng",
      value: "",
      key: "customer_name",
      width: 3
    },


    {
      component: "dateRange",
      label: "Nhập thời gian tạo đơn",
      value: [],
      key: "time",
      width: 3,
      onChange: (e) => {
        this.setState({ date: e.value })
      },
      from: "date_from",
      to: "date_to",
    },
    {
      component: "select",
      label: "Trạng thái đơn hàng",
      options: [],
      value: 1,
      key: "order_status_id",
      width: 3,
      optionLabel: "name",
      optionValue: "id",
    },
  ]


  showSuccess() {
    this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Thành công', life: 3000 });
  }

  showError(name) {
    this.toast.show({ severity: 'error', summary: 'Error Message', detail: name || 'Có lỗi xảy ra', life: 3000 });
  }


  async componentDidMount() {
    //TODO:
    let response = await axios.get(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/order/config`)
    if (response && response.data && response.data.data) {
      let data = response.data.data
      this.renderFields[2].options = data.order_status


      this.setState({ orderStatus: data.order_status })
    }
    this.getTable({ order_status_id: this.renderFields[2].value })
    this.setState({ loading: true })
    return true
  }



  async getTable(data) {
    let response = await axios.get(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/order`, {
      params: {
        ...data
      }
    });
    if (response && response.data && response.data.data) {
      let dataR = _.map(response.data.data.data, ite => {
        return { ...ite, created_at: moment(ite.created_at).format("HH:mm:ss DD/MM/YYYY") }
      })
      this.setState({ data: dataR, totalRecords: response.data.data.total })
      // console.log(this.state.data)
    }
  }
  onKeyPressHandler = (event) => {
    if (event.charCode === 13) {
      let data = {}
      for (let i = 0; i < this.renderFields.length; i++) {
        data[this.renderFields[i].key] = this.renderFields[i].value
      }
      this.getTable(data)
    }
  }
  idTemplate = (rowData) => {
    return (
      <a href={`/add/order_by_id/${rowData.id}`}>{rowData.id}</a>
    );
  }
  nameTemplate = (rowData) => {
    return (
      <a href={`/edit/product/${rowData.id}`}>{rowData.name}</a>
    );
  }
  deliveryHandle = (rowData) => {
    let iteCheck = rowData.delivery_from
    let delivery = ""
    if (rowData.delivery_from) {
      delivery = moment(rowData.delivery_from).add(7, "hour").format("DD/MM/YYYY")
    }
    if (rowData.delivery_to) {
      iteCheck = rowData.delivery_to
      delivery = moment(rowData.delivery_to).add(7, "hour").format("DD/MM/YYYY")
    }
    let check = moment(iteCheck).add(7, "hour").isSameOrBefore(new Date)
    let className = "huynt-text-blue"
    if (check) {
      className = "huynt-text-required"
    }
    return (
      <div className={className} style={{ "font-weight": "bold" }}>{delivery}</div>
    )
  }

  productTemplate = (rowData) => {
    // console.log("rowData", rowData)
    // let delivery = ""
    // if (ite.delivery_from) {
    //   delivery = moment(ite.delivery_from).add(7, "hour").format("DD/MM/YYYY")
    // }
    // if (ite.delivery_to) {
    //   delivery = moment(ite.delivery_to).add(7, "hour").format("DD/MM/YYYY")
    // }
    return (
      <>
        <DataTable value={rowData.product}
          // paginator
          headerColumnGroup={<ColumnGroup></ColumnGroup>}
          showGridlines
          rows={10}
        >

          <Column style={{ "width": "185px" }} field="name" body={this.nameTemplate} ></Column>
          <Column style={{ "width": "65px" }} field="total" ></Column>
          <Column style={{ "width": "115px" }} field="delivery_from" body={this.deliveryHandle} />
          {/* <Column style={{ "width": "120px" }} field="deposit_money" ></Column> */}
        </DataTable>
      </>
    )

    // return (
    //   <>
    //     {
    //       rowData.product.map(ite => {
    //         let iteCheck = ite.delivery_from
    //         let delivery = ""
    //         if (ite.delivery_from) {
    //           delivery = moment(ite.delivery_from).add(7, "hour").format("DD/MM/YYYY")
    //         }
    //         if (ite.delivery_to) {
    //           iteCheck = ite.delivery_to
    //           delivery = moment(ite.delivery_to).add(7, "hour").format("DD/MM/YYYY")
    //         }
    //         // if (ite.delivery_from) {
    //         //   delivery = moment(ite.delivery_from).add(7, "hour").format("DD/MM/YYYY")
    //         // }
    //         // if (delivery && ite.delivery_to) {
    //         //   delivery = delivery + " - " + moment(ite.delivery_to).add(7, "hour").format("DD/MM/YYYY")
    //         // }
    //         // else if (ite.delivery_to) {
    //         //   delivery = moment(ite.delivery_to).add(7, "hour").format("DD/MM/YYYY")
    //         // }
    // let check = moment(iteCheck).add(7, "hour").isSameOrBefore(new Date)
    // let className = "huynt-text-blue"
    // if (check) {
    //   className = "huynt-text-required"
    // }

    //         return (<>
    //           <div>{ite.name}<span style={{ "color": "red", "font-weight": "bold" }}> X {ite.total}</span></div>
    //           {iteCheck ?
    // <div className={className} style={{ "font-weight": "bold" }}>{delivery}</div>
    // : <div >{'\u00A0'}</div>}
    //         </>
    //         )
    //       })
    //     }
    //   </>
    // );
  }
  onBasicPageChange = async (event) => {
    let value = {}
    _.map(this.renderFields, ite => {
      if (ite.component !== "dateRange")
        value[ite.key] = ite.value
      else {
        value.date_from = ite.value[0]
        value.date_to = ite.value[1]
      }
    })
    this.setState({ first: event.first })
    await this.getTable({
      page_index: event.page + 1,
      ...value
    })
  }
  rowClass = (data) => {
    let className = ""
    _.map(data.product, ite => {
      let iteCheck = ite.delivery_from
      if (ite.delivery_to) {
        iteCheck = ite.delivery_to
      }
      let check = moment(iteCheck).add(7, "hour").isSameOrBefore(new Date)
      if (check) {
        className = "huynt-warning"

      }
    })
    return className
  }

  changeOrderStatus = async (order_id, order_status_id) => {
    let dataPost = {
      id: order_id,
      order_status_id
    }
    let response = await axios.put(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/order/status`, dataPost);
    if (response.data && response.data.data) {
      this.showSuccess()
      return true
    }
    else {
      this.showError()
      return false
    }
  }

  actionBodyTemplateOrderStatus = (rowData, options) => {
    let indexColor = _.findIndex(this.state.orderStatus, { id: rowData.order_status_id })
    let color = this.state.orderStatus[indexColor].color
    // console.log("color", color)

    return (
      <div className='p-grid'>
        <Dropdown
          style={{ "background-color": color, "color": "white" }}
          value={rowData.order_status_id}
          options={this.state.orderStatus}
          onChange={async (e) => {
            // rowData.order_status_id.value = e.value
            let success = await this.changeOrderStatus(rowData.id, e.value)
            if (success) {
              let data = this.state.data
              let rowIndex = _.findIndex(data, { id: rowData.id })
              data[rowIndex].order_status_id = e.value

              this.setState({ data: data })
            }
          }}
          optionLabel={"name"}
          optionValue={"id"}
        ></Dropdown>
      </div>
    );
  }
  render() {
    const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
    const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;
    return this.state.loading ? (
      <div onKeyPress={this.onKeyPressHandler} tabIndex="0" className="hidden-outline">
        <Toast ref={(el) => this.toast = el} />
        <Search renderFields={this.renderFields} onSearch={this.getTable} date={this.state.date}></Search>
        <DataTable value={this.state.data}
          rowClassName={this.rowClass}
          // paginator
          responsiveLayout="stack"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          rows={10}
          paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}>
          {/* <Column field="number_turn_code" header="Lần nhập liệu" sortable></Column> */}
          <Column style={{ "width": "25px" }} onRowEditSave={(e) => { }} body={this.idTemplate} header="Mã đơn"></Column>

          {/* <Column field="id" header="Mã đơn" sortable></Column> */}
          <Column style={{ "width": "150px" }} field="customer_name" header="Mã khách hàng" sortable></Column>
          <Column style={{ "width": "365px" }} onRowEditSave={(e) => { }} body={this.productTemplate} header="Tên sản phẩm"></Column>

          {/* <Column field="cavet_status_type_name" header="Tên sản phẩm"></Column> */}
          <Column style={{ "width": "185px" }} field="order_status_name" header="Trạng thái" body={this.actionBodyTemplateOrderStatus} ></Column>
          <Column field="note" header="Ghi chú"></Column>
          <Column style={{ "width": "120px" }} field="deposit_money" header="Cọc"></Column>
        </DataTable>
        <div className="paginator-demo">
          <Paginator first={this.state.first} rows={10} totalRecords={this.state.totalRecords} onPageChange={this.onBasicPageChange}></Paginator>
        </div>
      </div>
    ) : <i className="pi pi-spin pi-spinner" style={{ 'fontSize': '2em' }}></i>
  }

}
export default GetOrder;

