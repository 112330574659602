import React, { Component, useRef } from 'react';
import InputType2 from '../../components/InputType2';
import InputType3 from '../../components/InputType3';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Search from '../../components/Search';
import axios from '../../services/axios'
import _ from 'lodash'
import moment from "moment"
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import BlockUi from 'react-block-ui';
import localStorageServices from '../../services/localStorageServices';
import { Calendar } from 'primereact/calendar';
import { useParams } from "react-router-dom";
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import checkRequired from '../../services/checkRequired'
import uncheckRequired from '../../services/uncheckRequired'
import { InputText } from 'primereact/inputtext';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
class EditProduct extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      data: [
        // {
        //   gender: "Nam"
        // },
        // {
        //   gender: "Nữ"
        // },
      ],
      size: [
        // { size: "XL", note: "an com", total: 5, gender: "Nam", index: 1 },
        // { size: "XL", note: "an com ba", total: 5, gender: "Nữ", index: 1 },
      ],
      displayBasic4: false,
      sizeDeleted: [],
      dataShow: [],
      disabledSave: false,
      disabledExport: true,
      disabledProduct: true,
      displayBasic3: false,
      config: {},
      dialog: {},
      change: false,
      blocking: false,
      disabledInput: false,
      intervalId: null,
      checkFalse: [],
      changeColor: true,
      date: [],
      renderFieldsLeft_1: [],
      renderFieldsRight_1: [],
      renderFieldsLeft_2: [],
      renderFieldsRight_2: [],
    }
    this.save = this.save.bind(this);
    this.showSuccess = this.showSuccess.bind(this);

  }

  randomNumber() {
    let user = localStorage.getItem("user")
    user = JSON.parse(user)
    let name = user.name
    let string = ''
    for (let i = 0; i < 3; i++) {
      string = string + name.charCodeAt(i)
    }
    // console.log("1",NIL_UUID)
    //  let UUID = uuidv4().replace(/[xy]/g, function(c) {
    //   var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    //   return v.toString(16);
    // })
    return string + moment(new Date()).unix()
  }
  // checkCavet = async (name) => {
  //   let patt = new RegExp("^[A-Z_0-9]+$");
  //   let res = patt.test(name);
  //   return res
  // }
  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }
  renderFieldsLeft_1 = [
    {
      component: "text",
      label: "Tên sản phẩm",
      width: 6,
      key: "product_name",
      required: true,
      inputClassName: "p-col-9",
      // label: "Nhãn chính",
      // component: "select",
      // options: [],
      // value: 1,
      // width: 6,
      // key: "product_note_other_3",
      // inputClassName: "p-col-9",
      // optionLabel: "name",
      // optionValue: "id"
    },
    {
      // component: "select",
      label: "Cách nhập giá",
      width: 0,
      // value: 1,
      // options: [
      //   { "id": 1, "name": "Đơn giá và VAT" },
      //   { "id": 2, "name": "Đơn giá và %VAT" }
      // ],
      // key: "product_price_method",
      // required: true,
      // inputClassName: "p-col-9",
      // optionLabel: "name",
      // optionValue: "id"
    },
    {
      component: "text-number-integer",
      label: "Đơn giá",
      width: 6,
      key: "product_price",
      // required: true,
      inputClassName: "p-col-9",
      // onChange: () => {
      // switch (this.state.renderFieldsLeft_1[1].value) {
      //   case 1:
      //     if (this.state.renderFieldsLeft_1[2].value && this.state.renderFieldsLeft_1[3].value) {
      //       let renderFieldsLeft_1 = this.state.renderFieldsLeft_1
      //       renderFieldsLeft_1[4].value = (this.state.renderFieldsLeft_1[3].value / this.state.renderFieldsLeft_1[2].value - 1) * 100
      //       this.setState({ renderFieldsLeft_1: renderFieldsLeft_1 })
      //     }
      //     break;

      //   default:
      //     if (this.state.renderFieldsLeft_1[2].value && this.state.renderFieldsLeft_1[4].value) {
      //       let renderFieldsLeft_1 = this.state.renderFieldsLeft_1
      //       renderFieldsLeft_1[3].value = this.state.renderFieldsLeft_1[2].value + this.state.renderFieldsLeft_1[2].value * this.state.renderFieldsLeft_1[4].value / 100
      //       this.setState({ renderFieldsLeft_1: renderFieldsLeft_1 })
      //     }
      //     break;
      // }
      // }
    },

    {
      component: "text-number-integer",
      label: "Giá VAT",
      width: 6,
      key: "product_price_after_vat",
      // required: true,
      inputClassName: "p-col-9",
      // onChange: () => {
      //   switch (this.state.renderFieldsLeft_1[1].value) {
      //     case 1:
      //       if (this.state.renderFieldsLeft_1[2].value && this.state.renderFieldsLeft_1[3].value) {
      //         let renderFieldsLeft_1 = this.state.renderFieldsLeft_1
      //         renderFieldsLeft_1[4].value = (this.state.renderFieldsLeft_1[3].value / this.state.renderFieldsLeft_1[2].value - 1) * 100
      //         this.setState({ renderFieldsLeft_1: renderFieldsLeft_1 })
      //       }
      //       break;

      //     default:
      //       if (this.state.renderFieldsLeft_1[2].value && this.state.renderFieldsLeft_1[4].value) {
      //         let renderFieldsLeft_1 = this.state.renderFieldsLeft_1
      //         renderFieldsLeft_1[3].value = this.state.renderFieldsLeft_1[2].value + this.state.renderFieldsLeft_1[2].value * this.state.renderFieldsLeft_1[4].value / 100
      //         this.setState({ renderFieldsLeft_1: renderFieldsLeft_1 })
      //       }
      //       break;
      //   }
      // }
    },

    {
      component: "text-number",
      label: "%VAT",
      width: 6,
      key: "product_vat_percentage",
      required: true,
      inputClassName: "p-col-9",
      // onChange: () => {
      //   switch (this.state.renderFieldsLeft_1[1].value) {
      //     case 1:
      //       if (this.state.renderFieldsLeft_1[2].value && this.state.renderFieldsLeft_1[3].value) {
      //         let renderFieldsLeft_1 = this.state.renderFieldsLeft_1
      //         renderFieldsLeft_1[4].value = (this.state.renderFieldsLeft_1[3].value / this.state.renderFieldsLeft_1[2].value - 1) * 100
      //         this.setState({ renderFieldsLeft_1: renderFieldsLeft_1 })
      //       }
      //       break;

      //     default:
      //       if (this.state.renderFieldsLeft_1[2].value && this.state.renderFieldsLeft_1[4].value) {
      //         let renderFieldsLeft_1 = this.state.renderFieldsLeft_1
      //         renderFieldsLeft_1[3].value = this.state.renderFieldsLeft_1[2].value + this.state.renderFieldsLeft_1[2].value * this.state.renderFieldsLeft_1[4].value / 100
      //         this.setState({ renderFieldsLeft_1: renderFieldsLeft_1 })
      //       }
      //       break;
      //   }
      // }
    },

    {
      component: "textarea",
      label: "Vải chính",
      width: 6,
      key: "product_fabric_main",
      required: true,
      inputClassName: "p-col-9",
    },


    {
      component: "textarea",
      label: "Vải phối(lót,phụ)",
      width: 6,
      key: "product_fabric_sub",
      required: true,
      inputClassName: "p-col-9",
    },

    {
      component: "text",
      label: "Nhà vải",
      width: 6,
      key: "product_fabric_home",
      required: true,
      inputClassName: "p-col-9",
    },
    {
      component: "image",
      label: "Đính kèm",
      width: 6,
      key: "customer_name",

      inputClassName: "p-col-9",
      mode: "basic",
      accept: "image/*",
      // labelClassName: "input-invisible",
      // nonCol: true,
      buttonLabel: "Nhập ảnh",
      width: 2,
      buttonLabelDelete: "Xóa",
      key: "image",
      deleted: true,
      className: "p-button-danger",
      onSelect: async (event) => {
        let file = event.files[0]
        if (file.type.indexOf("image") > -1) {
          this.setState({
            blocking: true
          })
          let imageBase64 = await this.convertBase64(file)
          try {
            let response = await axios.post(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/image`, { value: imageBase64, label: "MH1" });
            if (response.data && response.data.data) {
              this.showSuccess()
              this.renderFieldsLeft_1[8].data.push(imageBase64)
              let data = response.data.data
              this.renderFieldsLeft_1[8].value.push(data.id)
              this.setState({
                blocking: false,
                renderFieldsLeft_1: this.renderFieldsLeft_1
              })
            }
          }
          catch (err) {
            console.log(err)
            this.setState({
              blocking: false
            })
            this.showError()
          }
        }
      },
      data: [],
      value: [],
      onClickDelete: async (event) => {
        try {
          let response = await axios.put(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/image`, { id: this.renderFieldsLeft_1[8].value[this.renderFieldsLeft_1[8].value.length - 1], deleted: 1 });
          this.showSuccess()
          this.renderFieldsLeft_1[8].data.pop()
          this.renderFieldsLeft_1[8].value.pop()
          this.setState({
            blocking: false,
            renderFieldsLeft_1: this.renderFieldsLeft_1
          })

        }
        catch (err) {
          console.log(err)
          this.setState({
            blocking: false
          })
          this.showError()
        }

      },

    },
    {
      component: "text-number-integer",
      label: "Số lượng tổng",
      width: 6,
      key: "product_total",

      inputClassName: "p-col-9",
    },
  ]
  renderFieldsLeft_2 = [
    {
      component: "text",
      headerLabel: "THÔNG TIN PHÒNG CẮT",
      label: "Sử dụng bộ rập",
      width: 6,
      key: "product_mold",

      inputClassName: "p-col-9",
    },
    {
      component: "textarea",
      label: "Lưu ý sử dụng bộ rập",
      width: 6,
      key: "product_mold_note",

      inputClassName: "p-col-9",
    },
    {
      component: "tableAdd",
      label: "Các chi tiết in",
      labelHeader: "Các chi tiết in",
      width: 6,
      key: "product_print_detail",
      arrayKey: [{ key: "detail", type: "text" }, {
        key: "amount", type: "text-number-integer", style: { "max-width": "80px", "width": "80px" }
      }],
      inputClassName: "p-col-9",
      // value: [{ number: 1, detail: '', amount: '' }],
      value: null,
      header: ["Chi tiết", "Số lượng"],
    },

    {
      component: "tableAdd",
      label: "Các chi tiết thêu",
      labelHeader: "Các chi tiết thêu",
      width: 6,
      key: "product_embroider_detail",
      arrayKey: [{ key: "detail", type: "text" }, {
        key: "amount", type: "text-number-integer", style: { "max-width": "80px", "width": "80px" }
      }],
      // value: [{ number: 1, detail: '', amount: '' }],
      value: null,
      header: ["Chi tiết", "Số lượng"],
      inputClassName: "p-col-9",
    },

    {
      component: "textarea",
      label: "Các lưu ý khác",
      width: 6,
      key: "product_note_other",

      inputClassName: "p-col-9",
    },

    {
      component: "text",
      headerLabel: "THÔNG TIN LÀM ÁO MẪU",
      label: "Size áo mẫu",
      width: 6,
      key: "product_size",

      inputClassName: "p-col-9",
    },
    {
      component: "textarea",
      label: "Các lưu ý khác",
      width: 6,
      key: "product_note_other_2",

      inputClassName: "p-col-9",
    },

    {
      component: "text",
      label: "In/Thêu",
      width: 6,
      key: "product_embroider",

      inputClassName: "p-col-9",
    },
  ]

  renderFieldsRight_1 = [
    {
      component: "text",
      label: "Tên nhà cung cấp",
      width: 6,
      key: "product_manufacture",
      headerLabel: "THÔNG TIN PHÒNG THÊU",
      inputClassName: "p-col-9",
    },

    {
      component: "textarea",
      label: "Các lưu ý khác",
      width: 6,
      key: "product_note",
      rows: 3.7,

      inputClassName: "p-col-9",
    },
    {
      component: "checkbox",
      label: "Đính kèm minh họa",
      width: 6,
      inputClassName: "p-col-9",
      width: 2,
      key: "product_image_diaper",
      value: 0

    },
    {
      component: "checkbox",
      label: "Mẫu đã thực hiện",
      width: 6,
      inputClassName: "p-col-9",
      width: 2,
      key: "product_image_diaper_2",
      value: 0

    },
    // {
    //   component: "image",
    //   label: "Đính kèm minh họa",
    //   width: 6,
    //   key: "customer_name",

    //   inputClassName: "p-col-9",
    //   mode: "basic",
    //   accept: "image/*",
    //   buttonLabelDelete: "Xóa",
    //   // labelClassName: "input-invisible",
    //   // nonCol: true,
    //   buttonLabel: "Nhập ảnh",
    //   width: 2,
    //   key: "image",
    //   className: "p-button-danger",
    //   deleted: true,
    //   onSelect: async (event) => {
    //     let file = event.files[0]
    //     if (file.type.indexOf("image") > -1) {
    //       this.setState({
    //         blocking: true
    //       })
    //       let imageBase64 = await this.convertBase64(file)
    //       try {
    //         let response = await axios.post(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/image`, { value: imageBase64, label: "MH" });
    //         if (response.data && response.data.data) {
    //           this.showSuccess()
    //           this.renderFieldsRight_1[2].data.push(imageBase64)
    //           let data = response.data.data
    //           this.renderFieldsRight_1[2].value.push(data.id)
    //           this.setState({
    //             blocking: false,
    //             renderFieldsRight_1: this.renderFieldsRight_1
    //           })
    //         }
    //       }
    //       catch (err) {
    //         this.setState({
    //           blocking: false
    //         })
    //         this.showError()
    //       }
    //     }
    //   },
    //   onClickDelete: async (event) => {
    //     try {
    //       let response = await axios.put(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/image`, { id: this.renderFieldsRight_1[2].value[this.renderFieldsRight_1[2].value.length - 1], deleted: 1 });
    //       this.showSuccess()
    //       this.renderFieldsRight_1[2].data.pop()
    //       this.renderFieldsRight_1[2].value.pop()
    //       this.setState({
    //         blocking: false,
    //         renderFieldsRight_1: this.renderFieldsRight_1
    //       })

    //     }
    //     catch (err) {
    //       console.log(err)
    //       this.setState({
    //         blocking: false
    //       })
    //       this.showError()
    //     }

    //   },
    //   data: [],
    //   value: []

    // },
    // {
    //   component: "image",
    //   label: "Đính kèm mẫu",
    //   width: 6,
    //   key: "customer_name",

    //   inputClassName: "p-col-9",
    //   mode: "basic",
    //   accept: "image/*",
    //   // labelClassName: "input-invisible",
    //   // nonCol: true,
    //   buttonLabel: "Nhập ảnh",
    //   width: 2,
    //   key: "image",
    //   deleted: true,
    //   className: "p-button-danger",
    //   onSelect: async (event) => {
    //     let file = event.files[0]
    //     if (file.type.indexOf("image") > -1) {
    //       this.setState({
    //         blocking: true
    //       })
    //       let imageBase64 = await this.convertBase64(file)
    //       try {
    //         let response = await axios.post(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/image`, { value: imageBase64, label: "KM" });
    //         if (response.data && response.data.data) {
    //           this.showSuccess()
    //           this.renderFieldsRight_1[3].data.push(imageBase64)
    //           let data = response.data.data
    //           this.renderFieldsRight_1[3].value.push(data.id)
    //           this.setState({
    //             blocking: false,
    //             renderFieldsRight_1: this.renderFieldsRight_1
    //           })
    //         }
    //       }
    //       catch (err) {
    //         this.setState({
    //           blocking: false
    //         })
    //         this.showError()
    //       }
    //     }
    //   },
    //   onClickDelete: async (event) => {
    //     try {
    //       let response = await axios.put(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/image`, { id: this.renderFieldsRight_1[3].value[this.renderFieldsRight_1[3].value.length - 1], deleted: 1 });
    //       this.showSuccess()
    //       this.renderFieldsRight_1[3].data.pop()
    //       this.renderFieldsRight_1[3].value.pop()
    //       this.setState({
    //         blocking: false,
    //         renderFieldsRight_1: this.renderFieldsRight_1
    //       })

    //     }
    //     catch (err) {
    //       console.log(err)
    //       this.setState({
    //         blocking: false
    //       })
    //       this.showError()
    //     }

    //   },
    //   data: [],
    //   value: []

    // },
    {
      component: "tableAdd",
      label: "Số lượng hàng giao",
      labelHeader: "Hàng giao",
      width: 6,
      key: "product_delivery_amount",
      arrayKey: [{
        key: "detail",
        type: "text",
      }, {
        key: "amount", type: "text-number-integer",
        style: { "max-width": "80px", "width": "80px" }
      }],
      inputClassName: "p-col-9",
      // value: [{ number: 1, detail: '', amount: '' }],
      value: null,
      header: ["Chi tiết", "Số lượng"],
    },
  ]

  renderFieldsRight_2 = [
    {
      component: "text",
      label: "Đơn vị phụ trách",
      headerLabel: "THÔNG TIN PHÒNG MAY - GIA CÔNG",
      width: 6,
      key: "product_unit_in_charge",

      inputClassName: "p-col-9",
    },

    {
      component: "text",
      label: "Loại hàng cần may",
      width: 6,
      key: "product_sew_type",

      inputClassName: "p-col-9",
    },

    {
      component: "textarea",
      label: "Quy cách may",
      width: 6,
      key: "product_sew_structure",

      inputClassName: "p-col-9",
    },

    {
      label: "Nhãn chính",
      component: "select",
      options: [],
      value: null,
      width: 6,
      key: "product_label_main_id",
      inputClassName: "p-col-9",
      optionLabel: "name",
      optionValue: "id"
    },

    {
      label: "Nhãn size",
      component: "select",
      options: [],
      value: null,
      width: 6,
      key: "product_label_size_id",
      inputClassName: "p-col-9",
      optionLabel: "name",
      optionValue: "id"
    },

    {
      label: "Nhãn sườn",
      component: "select",
      options: [],
      value: null,
      width: 6,
      key: "product_label_side_id",
      inputClassName: "p-col-9",
      optionLabel: "name",
      optionValue: "id"
    },

    {
      label: "Nhãn khác",
      component: "select",
      options: [],
      value: null,
      width: 6,
      key: "product_label_other_id",
      inputClassName: "p-col-9",
      optionLabel: "name",
      optionValue: "id"
    },

    {
      component: "text",
      label: "Phụ liệu đi kèm",
      width: 6,
      key: "product_additive",

      inputClassName: "p-col-9",
    },
    {
      component: "checkbox",
      label: "Đính kèm mẫu",
      width: 6,
      inputClassName: "p-col-9",
      width: 2,
      key: "product_image_diaper_3",
      value: 0

    },
    // {
    //   component: "image",
    //   label: "Đính kèm mẫu",
    //   width: 6,
    //   key: "customer_name",

    //   inputClassName: "p-col-9",
    //   mode: "basic",
    //   accept: "image/*",
    //   buttonLabelDelete: "Xóa",
    //   // labelClassName: "input-invisible",
    //   // nonCol: true,
    //   buttonLabel: "Nhập ảnh",
    //   width: 2,
    //   key: "image",
    //   className: "p-button-danger",
    //   deleted: true,
    //   onSelect: async (event) => {
    //     let file = event.files[0]
    //     if (file.type.indexOf("image") > -1) {
    //       this.setState({
    //         blocking: true
    //       })
    //       let imageBase64 = await this.convertBase64(file)
    //       try {
    //         let response = await axios.post(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/image`, { value: imageBase64, label: "KM1" });
    //         if (response.data && response.data.data) {
    //           this.showSuccess()
    //           this.renderFieldsRight_2[8].data.push(imageBase64)
    //           let data = response.data.data
    //           this.renderFieldsRight_2[8].value.push(data.id)
    //           this.setState({
    //             blocking: false,
    //             renderFieldsRight_2: this.renderFieldsRight_2
    //           })
    //         }
    //       }
    //       catch (err) {
    //         this.setState({
    //           blocking: false
    //         })
    //         this.showError()
    //       }
    //     }
    //   },
    //   onClickDelete: async (event) => {
    //     try {
    //       let response = await axios.put(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/image`, { id: this.renderFieldsRight_2[8].value[this.renderFieldsRight_2[8].value.length - 1], deleted: 1 });
    //       this.showSuccess()
    //       this.renderFieldsRight_2[8].data.pop()
    //       this.renderFieldsRight_2[8].value.pop()
    //       this.setState({
    //         blocking: false,
    //         renderFieldsRight_2: this.renderFieldsRight_2
    //       })

    //     }
    //     catch (err) {
    //       console.log(err)
    //       this.setState({
    //         blocking: false
    //       })
    //       this.showError()
    //     }

    //   },
    //   data: [],
    //   value: []

    // },


    {
      component: "textarea",
      label: "Các lưu ý khác",
      width: 6,
      key: "product_note_other_4",

      inputClassName: "p-col-9",
    },
    {
      label: "Trạng thái",
      component: "select",
      options: [],
      value: 1,
      width: 6,
      key: "product_status_id",
      inputClassName: "p-col-9",
      optionLabel: "name",
      optionValue: "id"
    },
  ]




  showSuccess() {
    this.toast.show({ severity: 'success', summary: 'Success Message', detail: 'Thành công', life: 3000 });
  }

  showError() {
    this.toast.show({ severity: 'error', summary: 'Error Message', detail: 'Có lỗi xảy ra', life: 3000 });
  }
  disabledInput(check) {
    this.renderFieldsLeft_1.map(ite => {
      if (!ite.requireDisabled) {
        ite.disabled = check
      }
      return ite
    })
    this.setState({ disabledInput: check })
  }
  setStateAsync = async (state) => {
    return new Promise(resolve => {
      this.setState(state, resolve)
    })
  }

  async save() {
    // let data = this.state.renderFieldsLeft_1
    let date = this.state.date
    let uncheckData = uncheckRequired(this.state.renderFieldsLeft_1)
    await this.setStateAsync({ renderFieldsLeft_1: uncheckData })
    let [renderFieldsLeft_1, required] = checkRequired(this.state.renderFieldsLeft_1)
    if (required) {
      this.showError("Vui lòng điền thông tin bị thiếu")
      await this.setStateAsync({ blocking: false, renderFieldsLeft_1: renderFieldsLeft_1 })
    }

    // this.setState({ blocking: true })
    let dataPost = {}
    dataPost["image"] = []
    _.map(this.state.renderFieldsLeft_1, ite => {
      if (ite.key === "image") {
        dataPost[ite.key] = _.concat(dataPost[ite.key], ite.value)
      }
      else if (ite.key) {
        dataPost[ite.key] = ite.value
      }
    })
    _.map(this.state.renderFieldsLeft_2, ite => {
      if (ite.key === "image") {
        dataPost[ite.key] = _.concat(dataPost[ite.key], ite.value)
      }
      else if (ite.key) {
        dataPost[ite.key] = ite.value
      }
    })
    _.map(this.state.renderFieldsRight_1, ite => {
      if (ite.key === "image") {
        dataPost[ite.key] = _.concat(dataPost[ite.key], ite.value)
      }
      else if (ite.key) {
        dataPost[ite.key] = ite.value
      }
    })

    _.map(this.state.renderFieldsRight_2, ite => {
      if (ite.key === "image") {
        dataPost[ite.key] = _.concat(dataPost[ite.key], ite.value)
      }
      else if (ite.key) {
        dataPost[ite.key] = ite.value
      }
    })
    let { product_id } = this.props.params;
    dataPost = {
      ...dataPost,
      product_delivery_from: date[0],
      product_delivery_to: date[1],
      attribute: this.state.size,
      product_id: product_id,
      attribute_deleted: this.state.sizeDeleted
    }
    console.log("dataPost", dataPost)
    try {
      this.disabledInput(true)
      this.setState({
        disabledSave: true,
      })
      let response = await axios.put(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/product`, dataPost);
      if (response.data && response.data.data) {
        this.showSuccess()
        this.setState({
          disabledExport: false,
          blocking: false
        })
      }

    }
    catch (err) {
      // console.log("err", err)
      this.disabledInput(false)
      this.setState({
        disabledSave: false,
        disabledExport: false,
        blocking: false
      })
      this.showError()
    }
  }

  export = () => {
    // let response = await axios.get(`${process.env.REACT_APP_AUTH_BASE_URL}/profile`);
    // let data = this.state.data
    // let html =fs.readFileSync("./htmlInmẫu.html")
    // console.log(this.state)
    // console.log(this.renderFieldsLeft_1)
    window.open(`/printer/cavet?number_turn_code=${this.renderFieldsLeft_1[5].value}`
    );
  }

  createProduct = () => {
    window.open(`/add/product`);
  }

  getData = async (product_id) => {
    console.log("product_id", product_id)
    let response = await axios.get(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/product?product_id=${product_id}`)
    if (response && response.data && response.data.data && response.data.data.data) {
      let data = response.data.data.data
      let images = response.data.data.image
      // this.renderFieldsLeft_1[19].options = data.order_status
      // this.renderFieldsLeft_1[19].value = data.order_status[0].id
      console.log(data)
      let renderFieldsLeft_1 = this.renderFieldsLeft_1
      let renderFieldsLeft_2 = this.renderFieldsLeft_2
      let renderFieldsRight_1 = this.renderFieldsRight_1
      let renderFieldsRight_2 = this.renderFieldsRight_2
      let date = []
      if (data.delivery_from) {
        date[0] = new Date(data.delivery_from)
      }
      if (data.delivery_to) {
        date[1] = new Date(data.delivery_to)
      }


      _.map(data, (value, key) => {
        let index = _.findIndex(renderFieldsLeft_1, { key: "product_" + key })
        if (index > -1) {
          renderFieldsLeft_1[index].value = value
          return
        }
        index = _.findIndex(renderFieldsLeft_2, { key: "product_" + key })
        if (index > -1) {
          renderFieldsLeft_2[index].value = value
          return
        }
        index = _.findIndex(renderFieldsRight_1, { key: "product_" + key })
        if (index > -1) {
          renderFieldsRight_1[index].value = value
          return
        }

        index = _.findIndex(renderFieldsRight_2, { key: "product_" + key })
        if (index > -1) {
          renderFieldsRight_2[index].value = value
          return
        }

      })
      _.map(images, image => {
        switch (image.label) {
          case "MH":
            renderFieldsRight_1[2].data.push(image.value)
            renderFieldsRight_1[2].value.push(image.id)
            break;
          case "KM":
            renderFieldsRight_1[3].data.push(image.value)
            renderFieldsRight_1[3].value.push(image.id)
            break;
          case "KM1":

            renderFieldsRight_2[8].data.push(image.value)
            renderFieldsRight_2[8].value.push(image.id)
            break;
          case "MH1":
            renderFieldsLeft_1[8].data.push(image.value)
            renderFieldsLeft_1[8].value.push(image.id)
            break;
          default:
            break;
        }
      })
      let size = []
      let genderData = []
      if (!_.isEmpty(data.attribute)) {
        size = data.attribute
        size = _.sortBy(size, ["index", "gender"])
        let gender = _.groupBy(size, "gender")
        for (const key in gender) {
          if (Object.hasOwnProperty.call(gender, key)) {
            // const element = gender[key];
            genderData.push({ gender: key })
          }
        }
      }

      this.setState({
        renderFieldsLeft_1: renderFieldsLeft_1,
        renderFieldsLeft_2: renderFieldsLeft_2,
        renderFieldsRight_1: renderFieldsRight_1,
        renderFieldsRight_2: renderFieldsRight_2,
        // date: [new Date(),new Date()],
        date: date,
        size: size,
        data: genderData
      })
    }
  }


  async componentDidMount() {
    //TODO:
    let response = await axios.get(`${process.env.REACT_APP_AUTH_BASE_URL}/v1/api/product/config`)
    if (response && response.data && response.data.data) {
      let data = response.data.data
      this.renderFieldsRight_2[3].options = data.label_main
      this.renderFieldsRight_2[4].options = data.label_size
      this.renderFieldsRight_2[5].options = data.label_side
      this.renderFieldsRight_2[6].options = data.label_other
      this.renderFieldsRight_2[10].options = data.product_status


      this.setState({ config: data })
    }
    let { product_id } = this.props.params;
    let disabledProduct = this.state.disabledProduct
    if (product_id) {
      disabledProduct = false
    }
    if (!product_id) {
      return
    }
    this.getData(product_id)


    this.setState({
      loading: true,
      renderFieldsLeft_1: this.renderFieldsLeft_1,
      disabledProduct: disabledProduct,
      renderFieldsRight_1: this.renderFieldsRight_1,
      renderFieldsLeft_2: this.renderFieldsLeft_2,
      renderFieldsRight_2: this.renderFieldsRight_2
    })
    return true
  }


  actionBodyTemplate = (rowData) => {
    return (
      <div>
        <Button
          icon="pi pi-times"
          className="p-button-rounded p-button-danger"
          onClick={() => {
            let data = this.state.data

            let index = _.findIndex(data, { number: rowData.number })
            data.splice(index, 1)
            data = _.map(data, (ite, index) => {
              return { ...ite, number: data.length - index }
            })
            this.renderFieldsLeft_1[6].value = data.length
            let disabledSave = true
            if (data.length > 0) disabledSave = false
            localStorageServices.set("cavet1", data)
            this.setState({ data: data, disabledSave: disabledSave })
          }}
          disabled={this.state.disabledInput}
        />
      </div>
    );
  }
  renderFields = [
    { width: 12 },
    {
      component: "text",
      label: "Kích cỡ",
      width: 6,
      key: "attribute",

      inputClassName: "p-col-9",
    },]

  renderFields_2 = [
    { width: 12 },
    {
      component: "text",
      label: "Giới tính",
      width: 6,
      key: "sex",
      required: true,
      inputClassName: "p-col-9",
    },]


  actionBodyTemplate3 = (rowData, options) => {
    // console.log("rowData", rowData)
    let field = options.field.replace("field_", "")
    let index = _.findIndex(this.state.size, { gender: rowData.gender, index: Number(field) })
    if (rowData[field] === undefined && this.state.size[index].note)
      return (
        <div className='p-grid'>
          <div className='p-col-10'>
            <div className='p-col-12'>
              <div>SL</div>
              <InputNumber
                locale="de-DE"
                value={index > -1 ? this.state.size[index].total : 0}
                onChange={(e) => {
                  let size = this.state.size
                  size[index].total = e.value
                  this.setState({ size: size })
                  let renderFieldsLeft_1 = this.state.renderFieldsLeft_1
                  renderFieldsLeft_1[9].value = _.sumBy(size, "total")
                  this.setState({ size: size, renderFieldsLeft_1: renderFieldsLeft_1 })
                }}
                useGrouping={true}
              />
            </div>
            <div className='p-col-12'>
              <div>Ghi chú</div>
              <InputTextarea //style={{ "width": "99%" }}
                value={index > -1 ? this.state.size[index].note : ''}
                onChange={(e) => {
                  let size = this.state.size
                  size[index].note = e.target.value
                  this.setState({ size: size })
                }}
                rows={2}
                autoResize
                disabled={this.state.disabledInput} />
            </div>
          </div>
          <div className='p-col-2' style={{ "display": "flex", "align-items": "center" }}>
            <Button
              icon="pi pi-minus-circle huynt-minus"
              className="p-button-rounded p-button-danger huynt-button-invisible"
              onClick={() => {
                rowData[field] = false
                this.setState({ change: !this.state.change })
                // this.setState({ data: data, disabledSave: disabledSave })
              }}
            ></Button>
          </div>
        </div>
      )
    else if (rowData[field] === false || (rowData[field] === undefined) && !this.state.size[index].note)
      return (
        <div className='p-grid'>
          <div className='p-col-10'>
            <div className='p-col-12'>
              <div>SL</div>
              <InputNumber
                locale="de-DE"
                value={index > -1 ? this.state.size[index].total : 0}
                onChange={(e) => {
                  let size = this.state.size
                  size[index].total = e.value
                  this.setState({ size: size })
                  let renderFieldsLeft_1 = this.state.renderFieldsLeft_1
                  renderFieldsLeft_1[9].value = _.sumBy(size, "total")
                  this.setState({ size: size, renderFieldsLeft_1: renderFieldsLeft_1 })
                }}
                useGrouping={true}
              />
            </div>
          </div>
          <div className='p-col-2' style={{ "display": "flex", "align-items": "center" }}>
            <Button
              icon="pi pi-minus-circle huynt-minus"
              className="p-button-rounded p-button-danger huynt-button-invisible"
              onClick={() => {
                rowData[field] = !rowData[field]
                this.setState({ change: !this.state.change })
                // this.setState({ data: data, disabledSave: disabledSave })
              }}
            ></Button>
          </div>
        </div>
      )
    return (
      <div className='p-grid'>
        <div className='p-col-10'>
          <div className='p-col-12'>
            <div>SL</div>
            <InputNumber
              locale="de-DE"
              value={index > -1 ? this.state.size[index].total : 0}
              onChange={(e) => {
                let size = this.state.size
                size[index].total = e.value
                this.setState({ size: size })
                let renderFieldsLeft_1 = this.state.renderFieldsLeft_1
                renderFieldsLeft_1[9].value = _.sumBy(size, "total")
                this.setState({ size: size, renderFieldsLeft_1: renderFieldsLeft_1 })              }}
              useGrouping={true}
            />
          </div>
          <div className='p-col-12'>
            <div>Ghi chú</div>
            <InputTextarea //style={{ "width": "99%" }}
              value={index > -1 ? this.state.size[index].note : ''}
              onChange={(e) => {
                let size = this.state.size
                size[index].note = e.target.value
                this.setState({ size: size })
              }}
              rows={2}
              autoResize
              disabled={this.state.disabledInput} />
          </div>
        </div>
        <div className='p-col-2' style={{ "display": "flex", "align-items": "center" }}>
          <Button
            icon="pi pi-minus-circle huynt-minus"
            className="p-button-rounded p-button-danger huynt-button-invisible"
            onClick={() => {
              rowData[field] = !rowData[field]
              this.setState({ change: !this.state.change })
              // this.setState({ data: data, disabledSave: disabledSave })
            }}
          ></Button>
        </div>
      </div>
    );
  }
  onClick(name, position) {
    let state = {
      [`${name}`]: true,
    };

    if (position) {
      state = {
        ...state,
        position,
      }
    }
    this.setState(state);
  }
  onHide(name) {
    this.setState({
      [`${name}`]: false
    });
  }

  renderFooter1(name) {
    return (
      <div>
        <Button label="Hủy bỏ" icon="pi pi-times" onClick={() => this.onHide(name)} className="p-button-danger" />
        <Button label="Xác nhận" icon="pi pi-check" onClick={() => this.createFile(name)} autoFocus />
      </div>
    );
  }
  createFile = (name) => {
    let value
    switch (name) {
      case "displayBasic3":
        value = this.renderFields[1].value
        if (value) {
          let size = this.state.size
          let index = 0
          if (size.length > 0) {
            index = size[size.length - 1].index
          }
          _.map(this.state.data, ite => {
            size.push({ size: value, note: "", total: 0, gender: ite.gender, index: index + 1 })
            // size.push({ size: value, note: "", total: 0, gender: "Nữ", index: index + 1 })
          })
          this.renderFields[1].value = ""
          this.setState({ size: size })
        }
        break;

      default:
        value = this.renderFields_2[1].value
        if (value) {
          let size = this.state.size
          let data = this.state.data
          data.push({ gender: value })
          // if (size.length > 0) {
          //   index = size[size.length - 1].index
          // }
          let groupIndex = _.groupBy(size, "index")
          _.map(groupIndex, ite => {
            size.push({ size: ite[0].size, note: "", total: 0, gender: value, index: ite[0].index })
          })
          size = _.sortBy(size, ["index", "gender"])

          // _.map(this.state.gender, ite => {
          //   size.push({ size: value, note: "", total: 0, gender: ite, index: index + 1 })
          //   // size.push({ size: value, note: "", total: 0, gender: "Nữ", index: index + 1 })
          // })
          this.renderFields_2[1].value = ""
          this.setState({ size: size, data: data })
        }
        break;
    }

    this.onHide(name)
  }

  addAttribute = () => {
    this.onClick('displayBasic3', null)
  }


  deletedAttribute = () => {
    let size = this.state.size
    if (size.length > 0) {
      let sizeDeleted = this.state.sizeDeleted
      sizeDeleted.push(size[size.length - 1].id)
      sizeDeleted.push(size[size.length - 2].id)
      size.pop()
      size.pop()
      this.setState({ size: size, sizeDeleted: sizeDeleted })
    }

  }
  headerTemplate = (size, index) => {
    return (<div className="table-header">
      <span style={{ "line-height": "2" }}>{size}</span>
      <Button
        icon="pi pi-minus-circle huynt-minus"
        className="p-button-rounded p-button-danger huynt-button-invisible"
        onClick={() => {
          let sizeDeletedState = this.state.sizeDeleted
          let sizeState = this.state.size
          _.map(sizeState, ite => {
            if (ite.size === size && ite.index === index)
              sizeDeletedState.push(ite.id)
          })
          _.remove(sizeState, ite => {
            if (ite.size == size && ite.index == index)
              return ite
          })
          this.setState({ size: sizeState, sizeDeleted: sizeDeletedState })

          // this.setState({ data: data, disabledSave: disabledSave })
        }}
      ></Button>
    </div>)
  }
  addSex = () => {
    this.onClick('displayBasic4', null)
  }

  actionBodyTemplateSex = (rowData, options) => {
    return (
      <div className='p-grid'>
        <span style={{ "line-height": "2" }}>{rowData.gender}</span>
        <Button
          icon="pi pi-minus-circle huynt-minus"
          className="p-button-rounded p-button-danger huynt-button-invisible"
          onClick={() => {
            let sizeDeletedState = this.state.sizeDeleted
            let sizeState = this.state.size
            let data = this.state.data

            _.map(sizeState, ite => {
              if (ite.gender === rowData.gender)
                sizeDeletedState.push(ite.id)
            })
            _.remove(sizeState, ite => {
              if (ite.gender == rowData.gender)
                return ite
            })
            _.remove(data, ite => {
              if (ite.gender == rowData.gender)
                return ite
            })
            this.setState({ size: sizeState, sizeDeleted: sizeDeletedState, data: data })

          }}
        ></Button>
      </div>
    );
  }

  enterKeyPress = (event, name) => {
    if (event.charCode === 13) {
      this.createFile(name)
    }
  }
  print = (name) => {
    let { product_id } = this.props.params;
    window.open(`/printer/order/${name}?&product_id=${product_id}`);

  }

  render() {
    let check = false
    if (!_.isEmpty(this.state.checkFalse)) {
      check = true
    }
    const paginatorLeft = <Button type="button" icon="pi pi-refresh" className="p-button-text" />;
    const paginatorRight = <Button type="button" icon="pi pi-cloud" className="p-button-text" />;
    return this.state.loading ? (
      <div>
        <BlockUi tag="div" blocking={this.state.blocking}>
          <Toast ref={(el) => this.toast = el} />
          <div className="p-grid">
            <div className='p-col-12' style={{ "border-bottom": "1px solid", "margin-bottom": "30px" }}>
              <h2>Chi tiết sản phẩm</h2>
            </div>

            <InputType3 renderFieldsLeft={this.state.renderFieldsLeft_1} renderFieldsRight={this.state.renderFieldsRight_1} onSave={this.save}></InputType3>
          </div>
          {/* <div className='p-grid'>
            <div className="p-field p-col-2">
              <Button className="p-button-success" label="Thêm kích cỡ" onClick={this.addAttribute} style={{ "align-self": "center", "margin-top": "6px", "margin-bottom": "6px", "background-color": "#00C008", "width": "100%" }} />
            </div>
            <div className='p-col-1'></div>
            <div className="p-field p-col-2">
              <Button className="p-button-danger" label="Xóa kích cỡ trước đó" onClick={this.deletedAttribute} style={{ "align-self": "center", "margin-top": "6px", "margin-bottom": "6px", "width": "100%" }} />
              <Button className="" label="Thêm giới tính" onClick={this.addSex} style={{ "align-self": "center", "margin-top": "6px", "margin-bottom": "6px", "width": "100%" }} />
            </div>
          </div> */}

          <Dialog header={`Tạo kích cỡ mới`} visible={this.state.displayBasic3} style={{ width: '50vw' }}
            footer={this.renderFooter1('displayBasic3', this.state.dialog)}
            onHide={() => this.onHide('displayBasic3')}>
            <InputType2 renderFields={this.renderFields}></InputType2>
          </Dialog>
          <Dialog header={`Tạo giới tính mới`} visible={this.state.displayBasic4} style={{ width: '50vw' }}
            footer={this.renderFooter1('displayBasic4', this.state.dialog)}
            onHide={() => this.onHide('displayBasic4')}>
            <InputType2 renderFields={this.renderFields_2}></InputType2>
          </Dialog>


          <div className="p-grid" style={{ "margin-bottom": "40px" }}>
            <div className="p-field p-col-2">
              <div style={{ "font-weight": "bold" }}>Thêm giới tính:</div>
              <InputText
                value={this.renderFields_2[1].value}
                onKeyPress={(event) => this.enterKeyPress(event, "displayBasic4")}
                onChange={(e) => {
                  console.log(e.target.value)
                  this.renderFields_2[1].value = e.target.value
                  this.setState({ change: !this.state.change })
                }}
              ></InputText>
            </div>
            <div className='p-col-2'></div>
            <div className="p-field p-col-2">
              <div style={{ "font-weight": "bold" }}>Thêm kích cỡ:</div>
              <InputText
                value={this.renderFields[1].value}
                onKeyPress={(event) => this.enterKeyPress(event, "displayBasic3")}
                onChange={(e) => {
                  console.log(e.target.value)
                  this.renderFields[1].value = e.target.value
                  this.setState({ change: !this.state.change })
                }}
              ></InputText>
            </div>

            <div className="p-col-12 " style={{
              "padding-bottom": "30px",
              "border-bottom": "1px solid"
            }}>
              <DataTable value={this.state.data} rowClassName={this.state.rowClass} scrollable
              // paginator
              // paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              // rows={100}
              // paginatorLeft={paginatorLeft} paginatorRight={paginatorRight}
              >
                <Column style={{ "max-width": "100px", "width": "100px" }} field="gender" body={this.actionBodyTemplateSex} header="Giới tính"></Column>
                {this.state.size.map((size, index) => {
                  if (index % this.state.data.length == 0) {
                    return (
                      <Column style={{ "max-width": "200px", "width": "200px" }} onRowEditSave={(e) => { }} body={this.actionBodyTemplate3} header={this.headerTemplate(size.size, size.index)}></Column>)
                  }
                  return null
                })}
              </DataTable>
            </div>

          </div>

          <div className="p-grid">
            <InputType3 renderFieldsLeft={this.state.renderFieldsLeft_2} renderFieldsRight={this.state.renderFieldsRight_2} onSave={this.save}></InputType3>
            <div className={"p-col-6"}>
              <div className="p-grid" style={{ "margin-bottom": "25px" }}>
                <label className={"p-col"} style={{ "text-align": "left", "align-self": "center", "font-weight": "bold" }}>Thời gian giao hàng dự kiến
                </label>
                <div className={"p-col-9"}>
                  <Calendar
                    dateFormat="dd/mm/yy"
                    selectionMode="range" value={this.state.date} onChange={(e) => {
                      this.setState({ date: e.value })
                    }}></Calendar>
                </div>
              </div>
            </div>
          </div>
          <div className="p-grid">
            {/* <div className="p-field p-col-11"></div> */}
            <div className="p-field p-col-1 huynt-limit-button">
              <Button disabled={this.state.disabledSave} className="p-button-success" label="Lưu" onClick={this.save} style={{ "align-self": "center", "margin-top": "6px", "margin-bottom": "6px", "background-color": "#00C008", "width": "100%" }} />
            </div>
            <div className="p-field p-col-2 huynt-limit-button">
              <Button icon="pi pi-print" className="p-button-success " label="In Phiếu In Thêu" onClick={() => this.print("mau_1")} style={{ "align-self": "center", "margin-top": "6px", "margin-bottom": "6px", "background-color": "#00C008", "width": "100%" }} />
            </div>
            <div className="p-field p-col-2 huynt-limit-button">
              <Button icon="pi pi-print" className="p-button-success" label="In Phiếu May - Gia Công" onClick={() => this.print("mau_2")} style={{ "align-self": "center", "margin-top": "6px", "margin-bottom": "6px", "background-color": "#00C008", "width": "100%" }} />
            </div>
            <div className="p-field p-col-2 huynt-limit-button">
              <Button icon="pi pi-print" className="p-button-success" label="In Phiếu Cắt" onClick={() => this.print("mau_3")} style={{ "align-self": "center", "margin-top": "6px", "margin-bottom": "6px", "background-color": "#00C008", "width": "100%" }} />
            </div>
            <div className="p-field p-col-2 huynt-limit-button">
              <Button icon="pi pi-print" className="p-button-success " label="In Phiếu Mẫu" onClick={() => this.print("mau_4")} style={{ "align-self": "center", "margin-top": "6px", "margin-bottom": "6px", "background-color": "#00C008", "width": "100%" }} />
            </div>
            <div className="p-field p-col-3 huynt-limit-button"></div>




          </div>

        </BlockUi>
      </div>
    ) : <i className="pi pi-spin pi-spinner" style={{ 'fontSize': '2em' }}></i>
  }

}

export default withParams(EditProduct);

