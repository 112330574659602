import React, { useState } from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import localStorageService from "../services/localStorageServices";
import { Link } from 'react-router-dom'

export const AppProfile = () => {

    const [expanded, setExpanded] = useState(false);

    const onClick = (event) => {
        setExpanded(prevState => !prevState);
        event.preventDefault();
    }
    // const changeRoute = (event) => {
    //     setExpanded(prevState => !prevState);
    //     event.preventDefault();
    // }
    const user = localStorageService.get("user")
    return (
        <div className="layout-profile">
            <div>
                <img src={user.picture} alt="Profile" />
            </div>
            <div style={{ color: "#FFFFFF" }} >
                <span className="username">Hello {user.nickname || user.name}</span>
                {/* <i onClick={onClick} className="pi pi-fw pi-cog" /> */}
            </div>
            <CSSTransition classNames="p-toggleable-content" timeout={{ enter: 1000, exit: 450 }} in={expanded} unmountOnExit>
                <ul className={classNames({ 'layout-profile-expanded': expanded })}>


                    <li>
                        <Link to='/change-password'>
                            <button type="button" className="p-link"><i className="pi pi-fw pi-user" /><span>Đổi mật khẩu</span></button>
                        </Link>
                    </li>
                    {/* <li><button type="button" className="p-link"><i className="pi pi-fw pi-inbox" /><span>Notifications</span><span className="menuitem-badge">2</span></button></li>
                    <li><button type="button" className="p-link"><i className="pi pi-fw pi-power-off" /><span>Logout</span></button></li> */}
                </ul>
            </CSSTransition>
        </div >
    );

}
